import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from '../../app/hooks';

// Features
import { SessionState } from './sessionSlice';
import { SellerState } from '../seller/sellerSlice';

// API
import { sessionsApi } from '../../common/api';

// Utils
import { allowedRole, sellerActions } from '../../common/utils/sellerAccess';

// Components
import { AppBar, Toolbar, Grid, Box, IconButton, Menu, MenuItem, Typography } from '@mui/material';

import {
  Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon,
  Lock as LockIcon,
  Power as PowerIcon,
  Menu as MenuIcon,
  FileText as FileTextIcon,
  User as UserIcon,
  Mail as MailIcon,
  ShoppingCart as ShoppingIcon,
  ShoppingBag as JumpsellerIcon,
  Truck as TruckIcon,
  Search as SearchIcon,
} from 'react-feather';

import Avatar from '../../common/components/Avatar';

// Assets
import styles from './AppBar.module.scss';

interface BarProps {
  home?: string;
  handleHamburguer?: (event: any) => void;
  backoffice?: boolean;
  drawerOpen: boolean;
  darkenBg?: () => void;
  isMobile?: boolean;
}

const Bar = (props: BarProps): React.ReactElement => {
  const { user } = useSelector(({ session }: { session: SessionState }) => session);
  const { company } = useSelector(({ seller }: { seller: SellerState }) => seller);
  const history = useHistory();
  const resourceRoute = 'configuration';
  const access: string = company?.seller?.access || 'undefined';

  const [profileMenuEl, setProfileMenuEl] = React.useState(null);

  const handleProfileMenuOpen = (event: any) => {
    setProfileMenuEl(event.currentTarget);
    if (props.darkenBg) props.darkenBg();
  };

  const handleProfileMenuClose = () => {
    setProfileMenuEl(null);
    if (props.darkenBg) props.darkenBg();
  };

  const onLogout = () => {
    if (props.backoffice) {
      handleProfileMenuClose();
      history.push('/backoffice/login');
      sessionsApi.backofficeLogout();
    } else {
      handleProfileMenuClose();
      history.push('/logout');
      sessionsApi.logout();
    }
  };

  const onUpdatePassword = () => {
    handleProfileMenuClose();
    history.replace('/seller/update-password');
  };

  const onCompany = () => {
    handleProfileMenuClose();
    history.replace('/seller/company');
  };

  const onTransparency = () => {
    handleProfileMenuClose();
    history.replace('/seller/transparency');
  };

  const onPaymentSettings = () => {
    handleProfileMenuClose();
    history.replace('/seller/company/payment_links');
  };

  const onUsersSettings = () => {
    handleProfileMenuClose();
    history.replace('/seller/company/users');
  };

  const onEmailsSettings = () => {
    handleProfileMenuClose();
    history.replace('/seller/company/emails');
  };

  const onShopifySetup = () => {
    handleProfileMenuClose();
    history.replace('/seller/company/shopify_setup');
  };

  const onJumpsellerSetup = () => {
    handleProfileMenuClose();
    history.push('/seller/company/jumpseller_setup');
  };

  const onDispatchSetup = () => {
    handleProfileMenuClose();
    history.replace('/seller/company/dispatch_setup');
  };

  const onInvoicingSetup = () => {
    handleProfileMenuClose();
    history.replace('/seller/company/invoicing_setup');
  };

  return (
    <AppBar
      position="static"
      elevation={0}
      className={`${styles.appBar} ${props.drawerOpen ? styles.drawerOpen : styles.drawerClosed} ${
        props.isMobile && styles.isMobile
      }`}
    >
      <Toolbar className={styles.topBar}>
        <IconButton
          className={`${styles.hamburguerButton} ${props.drawerOpen && styles.mobileDrawerOpen}`}
          onClick={props.handleHamburguer}
        >
          <MenuIcon />
        </IconButton>
        <Grid container display="flex" justifyContent="flex-end" alignItems="center">
          <Grid item xs={4} md={6} display="flex" justifyContent="flex-end" alignItems="center">
            <Box
              className={styles.userControl}
              aria-label="account of current user"
              aria-controls="current-user-menu"
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
            >
              <Avatar
                text={user?.name || user?.email}
                img={company?.image || undefined}
                context="company"
              />
              <IconButton size="large" color="inherit" edge="end">
                <SettingsIcon />
              </IconButton>
            </Box>
            <Menu
              keepMounted
              id="current-user-menu"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'center', horizontal: 'center' }}
              anchorEl={profileMenuEl}
              open={Boolean(profileMenuEl)}
              onClose={handleProfileMenuClose}
              className={styles.profileMenu}
            >
              <Grid container className={styles.menuGrid}>
                <Avatar
                  text={user?.name || user?.email}
                  img={company?.image || undefined}
                  className={styles.avatar}
                  context="company"
                />
                <Grid item>
                  <Typography variant="h6">{user?.name || 'Usuario Sin Nombre'}</Typography>
                  <Typography variant="subtitle2">{user?.email}</Typography>
                </Grid>
              </Grid>
              {!props.backoffice && allowedRole(access, resourceRoute, sellerActions.action) && (
                <MenuItem onClick={onCompany}>
                  <ShoppingBagIcon /> Datos Empresa
                </MenuItem>
              )}
              {!props.backoffice && allowedRole(access, resourceRoute, sellerActions.action) && (
                <MenuItem onClick={onPaymentSettings}>
                  <SettingsIcon /> Links de pago
                </MenuItem>
              )}
              {!props.backoffice && allowedRole(access, resourceRoute, sellerActions.action) && (
                <MenuItem onClick={onUsersSettings}>
                  <UserIcon /> Usuarios
                </MenuItem>
              )}
              {!props.backoffice && allowedRole(access, resourceRoute, sellerActions.action) && (
                <MenuItem onClick={onEmailsSettings}>
                  <MailIcon /> Correos
                </MenuItem>
              )}
              {!props.backoffice &&
                allowedRole(access, resourceRoute, sellerActions.action) &&
                company?.has_shopify && (
                  <MenuItem onClick={onShopifySetup}>
                    <ShoppingIcon /> Shopify
                  </MenuItem>
                )}
              {!props.backoffice &&
                allowedRole(access, resourceRoute, sellerActions.action) &&
                company?.has_jumpseller && (
                  <MenuItem onClick={onJumpsellerSetup}>
                    <JumpsellerIcon /> Jumpseller
                  </MenuItem>
                )}
              {!props.backoffice && allowedRole(access, resourceRoute, sellerActions.action) && (
                <MenuItem onClick={onDispatchSetup}>
                  <TruckIcon /> Despacho
                </MenuItem>
              )}
              {!props.backoffice &&
                allowedRole(access, resourceRoute, sellerActions.list) &&
                company?.invoicing_provider && (
                  <MenuItem onClick={onInvoicingSetup}>
                    <FileTextIcon /> Emisión documentos
                  </MenuItem>
                )}
              {!props.backoffice && allowedRole(access, 'payment', sellerActions.list) && (
                <MenuItem onClick={onTransparency}>
                  <SearchIcon /> Transparencia
                </MenuItem>
              )}
              {!props.backoffice && (
                <MenuItem onClick={onUpdatePassword}>
                  <LockIcon /> Cambiar contraseña
                </MenuItem>
              )}
              <MenuItem onClick={onLogout}>
                <PowerIcon /> Cerrar sesión
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Bar;
