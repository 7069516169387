import React, { useEffect, useState } from 'react';

import {
  Typography,
  useMediaQuery,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
} from '@mui/material';

import { Statement, SinglePaymentBuyer } from '../../../app/type';
import { formatCurrency } from '../../../common/utils';

// import CardsPopup from './CardsPopup';

import styles from '../products/Product.module.scss';
import variables from '../../../common/styles/variables.module.scss';
import { hexToRgb } from '../utils';
import { useSelector } from 'react-redux';
import { ClientState } from '../clientSlice';
import { useShopCartContext } from '../../../common/contexts/ShopCart';
import Avatar from '../../../common/components/Avatar';

interface SinglePaymentBuyerCardProps {
  singlePaymentBuyer: SinglePaymentBuyer;
}

export const SinglePaymentBuyerCard = ({
  singlePaymentBuyer,
}: SinglePaymentBuyerCardProps): React.ReactElement => {
  // const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [statementsToShow, setStatementsToShow] = useState<Statement[]>();
  const isMobile = useMediaQuery(`(max-width:${variables.breakpointMedium})`);
  const { company } = useSelector(({ client }: { client: ClientState }) => client);
  const { statementsCart, removeStatementsCart, addStatementsCart } = useShopCartContext();
  const totalPlan = singlePaymentBuyer.single_payment?.render_amount;
  const rgbColor = company?.payment_design?.background_color
    ? hexToRgb(company?.payment_design?.background_color)
    : null;
  const cardsBackgroundColor = rgbColor
    ? `rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, 0.1)`
    : '#edeeff';

  const handleCheckbox = (statement: Statement) => {
    if (!statement) return;
    if (statementsCart.includes(statement.id)) {
      removeStatementsCart(statement);
    } else {
      addStatementsCart(statement);
    }
  };

  useEffect(() => {
    const statements = [singlePaymentBuyer.current_statement];
    setStatementsToShow(statements);
  }, []);

  return (
    <div className={styles.cardContainer}>
      {!isMobile && singlePaymentBuyer.single_payment?.image && (
        <div className={styles.imageContainer}>
          <Avatar
            className={styles.imagePaymentPlan}
            img={singlePaymentBuyer.single_payment.image}
            context="product"
          />
        </div>
      )}
      <div className={styles.infoPaymentPlanContainer}>
        {isMobile && (
          <div className={styles.mobileTopCard}>
            <div
              className={styles.letterCoontainer}
              style={{
                background: company?.payment_design?.background_color
                  ? company?.payment_design?.background_color
                  : '#4653e3',
              }}
            >
              <Typography>{singlePaymentBuyer.single_payment?.name[0].toUpperCase()}</Typography>
            </div>
            <div className={styles.cardInfo}>
              <Typography className={styles.singlePaymentText}>Pago único</Typography>
            </div>
          </div>
        )}
        <div className={styles.infoRow}>
          <div className={styles.nameContainer}>
            <div>
              <Typography className={styles.title}>Producto</Typography>
              <Typography className={styles.name}>
                {singlePaymentBuyer.single_payment?.name}
              </Typography>
            </div>
          </div>
          {!isMobile && (
            <div className={styles.cardInfo}>
              <Typography className={styles.singlePaymentText}>Pago único</Typography>
            </div>
          )}
        </div>
        <div
          className={styles.installmentsBox}
          style={{
            background: cardsBackgroundColor,
          }}
        >
          <div className={styles.installmentsInfo}>
            <Typography className={styles.debtTitle}>{`Total: ${formatCurrency(
              totalPlan
            )}`}</Typography>
          </div>
          <Divider variant="middle" sx={{ margin: '15px 0' }} />
          <div>
            <div className={styles.statementsList}>
              <Table sx={{ minWidth: 500 }}>
                <TableBody>
                  {statementsToShow?.map((statement: Statement) => (
                    <TableRow
                      key={statement.id}
                      className={`${statement.status === 'expired' && styles.debtRow}`}
                    >
                      <TableCell>
                        <Checkbox
                          checked={statementsCart.includes(statement.id || '')}
                          onChange={() => handleCheckbox(statement)}
                          style={{
                            color: statementsCart.includes(statement.id || '')
                              ? company?.payment_design?.background_color
                                ? company?.payment_design?.background_color
                                : '#4653e3'
                              : 'inherit',
                          }}
                        />
                      </TableCell>
                      <TableCell className={styles.row}>
                        <Typography>
                          {statement.currency === 'UF'
                            ? formatCurrency(statement.external_amount, statement.currency)
                            : formatCurrency(statement.amount)}
                        </Typography>
                      </TableCell>
                      <TableCell className={`${styles.row} ${styles.dateCell}`}>
                        <div>
                          <Typography>{statement.due_date?.replace(/\//g, '-') || '-'}</Typography>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SinglePaymentBuyerCard;
