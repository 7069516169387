import { store } from '../../../app/store';
import { ApiList, ApiObject, GenericIdName, GenericObject, Seller, User } from '../../../app/type';
import { startLoading, stopLoading } from '../../../features/seller/sellerSlice';

import client from '../client';

export const list = (companyId: string): Promise<ApiList<Seller>> => {
  store.dispatch(startLoading());
  return client({
    method: 'get',
    url: `/v1/sellers`,
    params: { company_id: companyId },
  })
    .then(({ data: body }: { data: ApiList<Seller> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ updateError: error });
      throw error;
    })
    .finally(() => {
      store.dispatch(stopLoading());
    });
};

interface SellerData extends Partial<Omit<Seller, 'user'>> {
  user?: Partial<User>;
}

export const create = (companyId: string, seller: SellerData): Promise<ApiObject<Seller>> => {
  store.dispatch(startLoading());
  return client({
    method: 'post',
    url: '/v1/sellers',
    data: { seller: seller, company_id: companyId, user: seller.user },
  })
    .then(({ data: body }: { data: ApiObject<Seller> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ updateError: error });
      throw error;
    })
    .finally(() => {
      store.dispatch(stopLoading());
    });
};

export const update = (companyId: string, seller: SellerData): Promise<ApiObject<Seller>> => {
  store.dispatch(startLoading());
  return client({
    method: 'put',
    url: `/v1/sellers/${seller.id}`,
    data: seller,
  })
    .then(({ data: body }: { data: ApiObject<Seller> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ updateError: error });
      throw error;
    })
    .finally(() => {
      store.dispatch(stopLoading());
    });
};

export const destroy = (sellerId?: string): Promise<ApiObject<Seller>> => {
  return client({
    method: 'delete',
    url: `/v1/sellers/${sellerId}`,
  })
    .then(({ data: body }: { data: ApiObject<Seller> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};

export const updateMails = (companyId: string, mails: GenericObject): Promise<ApiList<Seller>> => {
  return client({
    method: 'post',
    url: `/v1/sellers/update_mails`,
    data: { company_id: companyId, mails: mails },
  })
    .then(({ data: body }: { data: ApiList<Seller> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};

export const updateSellerCollectionCompanies = (
  sellerId: string,
  restricted_collection_companies: boolean,
  collectionCompanies: GenericIdName[]
): Promise<ApiObject<Seller>> => {
  return client({
    method: 'put',
    url: `/v1/sellers/${sellerId}/collection_companies`,
    data: { restricted_collection_companies, collection_companies: collectionCompanies },
  })
    .then(({ data: body }: { data: ApiObject<Seller> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};

export default {
  list,
  update,
  create,
  destroy,
  updateMails,
};
