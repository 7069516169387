// https://mui.com/material-ui/react-autocomplete/#checkboxes
import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { GenericIdName } from '../../app/type';
import { useFormikContext } from 'formik';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const CheckboxesTags = ({
  displayArray,
  label,
  placeholder,
  disabled,
  selectedIds,
  fieldName,
}: {
  displayArray: GenericIdName[];
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  selectedIds?: string[];
  fieldName: string;
}): JSX.Element => {
  const { setFieldValue } = useFormikContext();
  const handleChange = (event: React.ChangeEvent<unknown>, value: GenericIdName[]) => {
    setFieldValue(fieldName, value);
  };

  return (
    <Autocomplete
      multiple
      id="checkboxes-tags"
      options={displayArray}
      disabled={disabled}
      disableCloseOnSelect
      defaultValue={displayArray.filter((option) => selectedIds?.includes(option.id || ''))}
      getOptionLabel={(option) => option.name}
      onChange={handleChange} // ojo
      renderOption={(props, option, { selected }) => {
        const { ...optionProps } = props;
        return (
          <li key={option.id} {...optionProps}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.name}
          </li>
        );
      }}
      style={{ width: '100%' }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label || 'Label'}
          placeholder={placeholder || 'placeholder'}
        />
      )}
    />
  );
};
