import React, { Fragment, useState, useEffect } from 'react';
import { Switch, Route, Link, Redirect, useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from '../../app/hooks';
import variables from '../../common/styles/variables.module.scss';
import LogRocket from 'logrocket';
import { useSnackbar } from 'notistack';

// Types
import { ApiList, Company } from '../../app/type';

// Features
import { SellerState, setCompany } from './sellerSlice';
import { SessionState } from '../session/sessionSlice';

// API
import { sellerApi, sessionsApi } from '../../common/api';

// Components
import {
  useMediaQuery,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Button,
  Grid,
  IconButton,
  Divider,
  Typography,
} from '@mui/material';

import {
  CreditCard as CreditCardIcon,
  Copy as CopyIcon,
  User as UserIcon,
  Menu as MenuIcon,
  List as ListIcon,
} from 'react-feather';

import AppBar from '../session/AppBar';

import Dashboard from './dashboard/Index';

import CompanyShow from './company/Show';
import PaymentLinksEdit from './company/PaymentLinks';
import SellersConfiguration from './company/Sellers';
import EmailsConfiguration from './company/Emails';
import ShopifySetup from './company/ShopifySetup';
import DispatchSetup from './company/DispatchSetup';
import InvoicingSetup from './company/InvoicingSetup';
import JumpsellerSetup from './company/JumpsellerSetup';

import TransparencyIndex from './transparency/Index';

import ProductIndex from './product/Index';
import ProductShow from './product/Show';
import EditProduct from './product/Edit';

import SubscriptionShow from './subscription/Show';
import SubscriptionIndex from './subscription/Index';
import EditSubscription from './subscription/Edit';

import SinglePaymentsIndex from './singlePayment/Index';
import SinglePaymentsShow from './singlePayment/Show';
import EditSinglePayment from './singlePayment/Edit';

import BuyerIndex from './buyer/Index';
import BuyerShow from './buyer/Show';
import BuyerEdit from './buyer/Edit';
import Avatar from '../../common/components/Avatar';

import StatementIndex from './statement/Index';

import UpdatePassword from './dashboard/UpdatePassword';

import PaymentPlanIndex from './paymentPlan/Index';
import PaymentPlanEdit from './paymentPlan/Edit';
import PaymentPlanShow from './paymentPlan/Show';

import { PlatformBlock } from '../../common/components/Block';

// Assets
import styles from './Seller.module.scss';
import { initials } from '../../common/utils/index';
import PopUp from '../../common/components/PopUp';
import BackButton from '../../common/components/BackButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartColumn } from '@fortawesome/free-solid-svg-icons';
import { faSquare } from '@fortawesome/free-regular-svg-icons';
import NotificationBox from '../../common/components/Notification';
import { useNotifyContext } from '../../common/contexts/Notify';
import TagManager from 'react-gtm-module';
import { setItem } from '../../common/utils/localstorage';
import { ReactComponent as ProductsIcon } from '../../assets/images/seller/products_icon.svg';
import FileImportIndex from './file_imports/Index';

declare global {
  interface Window {
    $chatwoot: any;
    chatwootSDK: any;
    chatwootSettings: any;
  }
}

const Seller = (): React.ReactElement => {
  const { user } = useSelector(({ session }: { session: SessionState }) => session);
  const { company } = useSelector(({ seller }: { seller: SellerState }) => seller);
  const [open, setOpen] = useState<boolean>(false);

  const { notifications, removeNotification, addNotification } = useNotifyContext();

  const isMobile = useMediaQuery(`(max-width:${variables.breakpointMedium})`);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(true);
  const [opaqueBg, setOpaqueBg] = useState<boolean>(false);
  const { pathname } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  if (process.env.NODE_ENV === 'production' && user) {
    LogRocket.identify(user.id, {
      name: user.name,
      email: user.email,
    });
    const script = document.createElement('script');
    script.src = 'https://chatwoot.plaglabs.com/packs/js/sdk.js';
    script.async = true;
    script.defer = true;

    // Cuando el script se haya cargado, ejecutamos el código de Chatwoot
    script.onload = () => {
      (window as any).chatwootSDK.run({
        websiteToken: 'SdkxN3QBKtfZ9XM3NRbR47av',
        baseUrl: 'https://chatwoot.plaglabs.com',
      });
    };
    // Co<nfiguración adicional cuando Chatwoot esté listo
    window.addEventListener('chatwoot:ready', function () {
      window.$chatwoot.setUser(user?.id, {
        email: user.email, // Asegúrate de reemplazar estos valores
        name: user.name,
      });
    });

    // Ajustes de configuración del widget
    window.chatwootSettings = {
      type: 'expanded_bubble',
      launcherTitle: 'Soporte',
    };

    // Añadir el script al DOM
    document.body.appendChild(script);
  }

  useEffect(() => {
    sellerApi.companies
      .list()
      .then((data: ApiList<Company & { filters?: { [key: string]: any } }>) => {
        if (data.data[0]?.new_record) {
          history.replace('/seller-setup');
        } else if (pathname.match(/seller\/?$/)) {
          history.replace('/seller/home');
        }
        dispatch(setCompany(data.data[0]));
        data.data[0]?.filters && setItem('filter', data.data[0].filters);
        if (!data.data[0]?.completed_data) {
          if (!data.data[0]?.popup_showed && !data.data[0]?.new_record) {
            setOpen(true);
            TagManager.dataLayer({ dataLayer: { event: 'popup_showed', user: user?.id } });
          }
          if (history.location.pathname !== '/seller/company') {
            addNotification(
              <span>
                Te recordamos que debes <a href="/seller/company">completar tus datos</a>
              </span>,
              { variant: 'info' }
            );
          }
        }
      });
  }, []);

  useEffect(() => {
    setDrawerOpen(!isMobile);
  }, [isMobile]);

  const onDrawerButton = () => {
    setDrawerOpen((o: boolean) => !o);
  };

  const onRouteClick = () => {
    if (isMobile && drawerOpen) setDrawerOpen(false);
  };

  const RouteListItem = (props: {
    label: string;
    route: string;
    icon: React.ReactElement;
  }): React.ReactElement => {
    const { label, route, icon } = props;
    const selected = pathname.includes(`/seller/${route}`);

    return (
      <ListItem
        button
        key={route}
        component={Link}
        to={`/seller/${route}`}
        selected={selected}
        onClick={onRouteClick}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={label} />
      </ListItem>
    );
  };

  const darkenBg = () => {
    setOpaqueBg(!opaqueBg);
  };

  const closePopup = () => {
    setOpen(false);
    sellerApi.companies.turnOffPopup(company?.id || '');
  };

  function sellerAccessToModule(resource: string): boolean {
    const access = company?.seller?.access;
    const commonResources = [
      'home',
      'products',
      'subscriptions',
      'single_payments',
      'payment_plans',
      'buyers',
    ];
    const accountantResources = ['payments'];
    // const companyResources = ['company'];

    switch (access) {
      case 'admin':
        return true;
      case 'viewer':
      case 'editor':
        if (commonResources.includes(resource)) {
          return true;
        }
        return false;
      case 'accountant':
        if (accountantResources.includes(resource)) {
          return true;
        }
        return false;
      case 'accountant_viewer':
      case 'accountant_editor':
        if (accountantResources.includes(resource) || commonResources.includes(resource)) {
          return true;
        }
        return false;
      default:
        false;
    }
    return true;
  }

  return (
    <div className={`${styles.appContainer} ${opaqueBg && styles.appBarOpen}`}>
      <AppBar
        home="/seller/home"
        handleHamburguer={onDrawerButton}
        drawerOpen={drawerOpen}
        darkenBg={darkenBg}
        isMobile={isMobile}
      />
      <Drawer
        open={drawerOpen || !isMobile}
        onClose={() => setDrawerOpen(false)}
        sx={{
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
          },
        }}
        className={`${styles.drawer} ${!drawerOpen && styles.drawerClosed}`}
        variant={isMobile ? 'temporary' : 'persistent'}
        anchor="left"
      >
        <Grid container>
          <Grid item xs={12}>
            <div className={styles.drawer}>
              <div className={`${styles.topDrawer} ${!drawerOpen && styles.topDrawerClosed}`}>
                <Link to={'/seller/home' || '/'} className={styles.homeLink}>
                  <img
                    src="https://storage.googleapis.com/onlypays-public/assets/images/zafepay_logo_white.png"
                    alt="Logo Zafepay"
                  />
                </Link>
                {onDrawerButton && (
                  <IconButton className={styles.hamburguerButton} onClick={onDrawerButton}>
                    <MenuIcon />
                  </IconButton>
                )}
              </div>
            </div>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={12} display="flex" justifyContent="center">
              <Avatar
                text={user?.name || user?.email}
                img={company?.image || undefined}
                className={`${styles.avatar} ${!drawerOpen && styles.topDrawerClosed}`}
                context="company"
              />
            </Grid>
            <Grid item xs={12} textAlign="center">
              {drawerOpen ? (
                <Fragment>
                  <Typography variant="h5">{user?.name}</Typography>
                  <Typography variant="subtitle2">{user?.email}</Typography>
                </Fragment>
              ) : (
                <Typography variant="h6">{user ? initials(user?.name) : ''}</Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Divider variant="middle" sx={{ margin: '30px 0 10px 0' }} />
        {company?.block !== 'platform' && (
          <List>
            {company?.active_modules?.includes('home') && (
              <RouteListItem
                label="Resumen"
                route="home"
                icon={<FontAwesomeIcon icon={faChartColumn} />}
              />
            )}
            {company?.active_modules?.includes('products') && sellerAccessToModule('products') && (
              <RouteListItem
                label="Productos"
                route="products"
                icon={<ProductsIcon style={{ height: '25px' }} />}
              />
            )}
            {company?.active_modules?.includes('subscriptions') &&
              sellerAccessToModule('subscriptions') && (
                <RouteListItem label="Suscripciones" route="subscriptions" icon={<CopyIcon />} />
              )}
            {company?.active_modules?.includes('single_payments') &&
              sellerAccessToModule('single_payments') && (
                <RouteListItem
                  label="Pagos únicos"
                  route="single_payments"
                  icon={<FontAwesomeIcon icon={faSquare} />}
                />
              )}
            {company?.active_modules?.includes('payment_plans') &&
              sellerAccessToModule('payment_plans') && (
                <RouteListItem label="Planes de pago" route="payment_plans" icon={<ListIcon />} />
              )}
            {company?.active_modules?.includes('buyers') && sellerAccessToModule('buyers') && (
              <RouteListItem label="Clientes" route="buyers" icon={<UserIcon />} />
            )}
            {company?.active_modules?.includes('payments') && sellerAccessToModule('payments') && (
              <RouteListItem label="Pagos" route="payments" icon={<CreditCardIcon />} />
            )}
          </List>
        )}
      </Drawer>
      {isMobile && <div style={{ height: '56px' }}></div>}
      <div className={`${styles.mainContainer} ${!drawerOpen && styles.mainContainerWhenClosed}`}>
        {company?.block === 'platform' ? (
          user?.confirmed ? (
            <PlatformBlock />
          ) : (
            <PlatformBlock
              title=" "
              text="Antes de usar la plataforma, te pedimos que nos confirmes tu email a través del correo que te enviamos. Si no lo encuentras, podemos reenviarte otro correo al apretar el botón."
              image="https://storage.googleapis.com/onlypays-public/assets/images/girl%20and%20boy%20working%20together%20in%20front%20of%20laptop.svg"
              extraActions={[
                <Button
                  key={1}
                  variant="contained"
                  onClick={() =>
                    sessionsApi
                      .resendConfirmationEmail()
                      .then(enqueueSnackbar('Tu solicitud fue procesada', { variant: 'success' }))
                  }
                >
                  Reenviar correo de confirmación
                </Button>,
              ]}
              logo
            />
          )
        ) : (
          <>
            {notifications.map((notif) => (
              <NotificationBox key={notif.id} notification={notif} onRemove={removeNotification} />
            ))}
            {company?.block === 'payments' && (
              <div className={styles.warningContainer}>
                <Typography>
                  Cuenta no habilitada para cobrar. Cualquier duda contactarte con{' '}
                  <a href="mailto:hola@zafepay.com">hola@zafepay.com</a>
                </Typography>
              </div>
            )}
            {company === undefined ? null : (
              <Paper className={styles.dashBody} elevation={0}>
                {isMobile ? <BackButton sx={{ width: '30%', mb: '10px' }}></BackButton> : null}
                <Switch>
                  {/* HOME */}
                  <Route path="/seller/home">
                    <Dashboard />
                  </Route>
                  {/* SESSION */}
                  <Route path="/seller/update-password">
                    <UpdatePassword />
                  </Route>
                  {/* COMPANY */}
                  <Route path="/seller/company/payment_links">
                    <PaymentLinksEdit />
                  </Route>
                  <Route path="/seller/company/users">
                    <SellersConfiguration />
                  </Route>
                  <Route path="/seller/company/emails">
                    <EmailsConfiguration />
                  </Route>
                  <Route path="/seller/company/shopify_setup">
                    <ShopifySetup />
                  </Route>
                  <Route path="/seller/company/jumpseller_setup">
                    <JumpsellerSetup />
                  </Route>
                  <Route path="/seller/company/dispatch_setup">
                    <DispatchSetup />
                  </Route>
                  <Route path="/seller/company/invoicing_setup">
                    <InvoicingSetup />
                  </Route>
                  <Route path="/seller/company">
                    <CompanyShow />
                  </Route>
                  {/* TRANSPARENCY */}
                  <Route path="/seller/transparency">
                    <TransparencyIndex />
                  </Route>

                  {/* PRODUCTS */}
                  <Route path="/seller/products/new">
                    <EditProduct />
                  </Route>
                  <Route path="/seller/products/:productId/edit">
                    <EditProduct />
                  </Route>
                  <Route path="/seller/products/:productId">
                    <ProductShow />
                  </Route>
                  <Route path="/seller/products">
                    <ProductIndex />
                  </Route>

                  {/* SUBSCRIPTIONS */}
                  <Route path="/seller/subscriptions/new">
                    <EditSubscription />
                  </Route>
                  <Route path="/seller/subscriptions/:subscriptionId/new_buyer">
                    <BuyerEdit />
                  </Route>
                  <Route path="/seller/subscriptions/:subscriptionId/edit">
                    <EditSubscription />
                  </Route>
                  <Route path="/seller/subscriptions/:subscriptionId">
                    <SubscriptionShow />
                  </Route>
                  <Route path="/seller/subscriptions">
                    <SubscriptionIndex />
                  </Route>

                  {/* SINGLE PAYMENTS */}
                  <Route path="/seller/single_payments/new">
                    <EditSinglePayment />
                  </Route>
                  <Route path="/seller/single_payments/:singlePaymentId/new_buyer">
                    <BuyerEdit />
                  </Route>
                  <Route path="/seller/single_payments/:singlePaymentId/edit">
                    <EditSinglePayment />
                  </Route>
                  <Route path="/seller/single_payments/:singlePaymentId">
                    <SinglePaymentsShow />
                  </Route>
                  <Route path="/seller/single_payments">
                    <SinglePaymentsIndex />
                  </Route>

                  {/* PAYMENT PLANS */}
                  <Route path="/seller/payment_plans/new">
                    <PaymentPlanEdit />
                  </Route>
                  <Route path="/seller/payment_plans/:paymentPlanId/edit">
                    <PaymentPlanEdit />
                  </Route>
                  <Route path="/seller/payment_plans/:paymentPlanId">
                    <PaymentPlanShow />
                  </Route>
                  <Route path="/seller/payment_plans">
                    <PaymentPlanIndex />
                  </Route>

                  {/* BUYERS */}
                  <Route path="/seller/buyers/new">
                    <BuyerEdit />
                  </Route>
                  <Route path="/seller/buyers/:buyerId/edit">
                    <BuyerEdit />
                  </Route>
                  <Route path="/seller/buyers/:buyerId">
                    <BuyerShow />
                  </Route>
                  <Route path="/seller/buyers">
                    <BuyerIndex />
                  </Route>
                  {/* PAYMENTS */}
                  <Route path="/seller/payments">
                    <StatementIndex />
                  </Route>
                  <Route path="/seller/file_imports">
                    <FileImportIndex />
                  </Route>
                  {/* 404 */}
                  <Route path="*">
                    <Redirect to="/seller/home" />
                  </Route>
                </Switch>
              </Paper>
            )}
            <PopUp
              state={{ open, setOpen }}
              title={
                <Typography variant="h6" align="center">
                  ¡Bienvenido {user?.name} &#128075;!
                </Typography>
              }
              content={
                <Typography sx={{ marginTop: '20px' }} variant="body1" align="center">
                  Te recordamos que debes <b>completar tus datos</b>, si ya lo hiciste, te invitamos
                  a que comiences a interactuar con la aplicación <b>creando tus servicios</b> y
                  posteriormente, <b>agregando tus clientes.</b>
                </Typography>
              }
              extraActions={[
                <Button
                  key={1}
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={closePopup}
                >
                  <b>Comenzar</b> &#128640;
                </Button>,
              ]}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Seller;
