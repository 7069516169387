import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, Tooltip } from '@mui/material';

import { ACCESS_DESCRIPTIONS } from '../../../common/constants/descriptions';
import { SellerState } from '../../../../src/features/seller/sellerSlice';
import { FormikProps } from 'formik';
import { useSelector } from '../../../app/hooks';

import { Seller, User } from '../../../app/type';

interface SellerInfo extends Partial<Omit<Seller, 'user'>> {
  user?: Partial<User>;
} // importar?
interface props {
  formik: FormikProps<SellerInfo>;
}

const SellerSelectorForm = ({ formik }: props): React.ReactElement => {
  const { company } = useSelector(({ seller }: { seller: SellerState }) => seller);
  const sellerAccess = company?.seller?.access;

  function disableRole(role: string): boolean {
    // Only admin can change the role to admin
    if (role === 'admin' && sellerAccess === 'admin') {
      return false;
    } else if (role === 'admin') {
      return true;
    }
    switch (sellerAccess) {
      case 'admin':
        return false;
      case 'viewer':
      case 'accountant_viewer':
      case 'editor':
      case 'accountant_editor':
      default:
        return true;
    }
  }

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id="access-label">Rol</InputLabel>
      <Select
        labelId="access-label"
        id="access"
        name="access"
        value={formik.values.access}
        onChange={formik.handleChange}
        label="Acceso"
      >
        {Object.keys(ACCESS_DESCRIPTIONS).map((key) => (
          <MenuItem
            key={ACCESS_DESCRIPTIONS[key as keyof typeof ACCESS_DESCRIPTIONS].value}
            value={ACCESS_DESCRIPTIONS[key as keyof typeof ACCESS_DESCRIPTIONS].value}
            disabled={disableRole(
              ACCESS_DESCRIPTIONS[key as keyof typeof ACCESS_DESCRIPTIONS].value
            )}
          >
            <Tooltip
              title={ACCESS_DESCRIPTIONS[key as keyof typeof ACCESS_DESCRIPTIONS].description}
              placement="left-start"
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: 'white',
                    color: '#0d3a56',
                    maxWidth: '250px',
                    padding: '8px',
                    fontSize: '12px',
                    border: '1px solid #CCCCCC',
                  },
                },
              }}
            >
              <div style={{ width: '100%' }}>
                {ACCESS_DESCRIPTIONS[key as keyof typeof ACCESS_DESCRIPTIONS].title}
              </div>
            </Tooltip>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SellerSelectorForm;
