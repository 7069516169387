// React, packages and api
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { sellerApi } from '../../../common/api';
import { useHistory, Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import copyToClipboard from 'copy-text-to-clipboard';
import QRCode from 'qrcode';
import { jsPDF } from 'jspdf';
import moment from 'moment';

// Slice and utils
import { useSelector, useDispatch } from '../../../app/hooks';
import { SellerState, setBuyer, setSubscription } from '../sellerSlice';
import { SessionState } from '../../session/sessionSlice';
import {
  checkPaymentStatus,
  dateByString,
  formatCurrency,
  parseDatetime,
  setDocumentTitle,
} from '../../../common/utils';
import { RECURRENCE_ES } from '../../../common/constants/subscriptions';
import { DATE_UNITS_ES, PLURAL_DATE_UNITS_ES, WEEKDAYS_ES } from '../../../common/constants/dates';
import { FRONT_URL } from '../../../common/api/client';
import { allowedRole, sellerActions } from '../../../common/utils/sellerAccess';

// MUI
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import useMediaQuery from '@mui/material/useMediaQuery';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import {
  InputAdornment,
  CircularProgress,
  Modal,
  Container,
  TextField,
  Menu,
  MenuItem,
  Drawer,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Skeleton,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Styles and assets
import styles from './Show.module.scss';
import variables from '../../../common/styles/variables.module.scss';
import cStyles from '../../../common/styles/common.module.scss';
import { Edit as EditIcon, Trash as TrashIcon, Copy as CopyIcon, X as XIcon } from 'react-feather';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCopy,
  faQrcode,
  faEye,
  faPlusSquare,
  faPaperPlane,
  faComment,
  faMoneyCheck,
  faMinusSquare,
  faTrashCan,
  faUserXmark,
  faBagShopping,
  faCreditCard,
  faUsers,
  faXmark,
  faFilePen,
  faRepeat,
  faArrowUpRightFromSquare,
} from '@fortawesome/free-solid-svg-icons';

// Components
import ResourceList from '../../../common/components/ResourceList';
import StatusLabel from '../../../common/components/StatusLabel';
import PopUp from '../../../common/components/PopUp';
import InfoBox from '../../../common/components/InfoBox';
import Stepper from '../../../common/components/Stepper';
import { Search as SearchIcon } from 'react-feather';
import { ChangeSubscriptionForm } from './ChangeSubscriptionForm';

// Types
import {
  SubscriptionBuyer,
  Statement,
  Buyer,
  Email,
  ApiList,
  PaymentTransaction,
  ProductHistory,
  Note,
  InvoiceDocument,
  Subscription,
  ApiObject,
  ExtraField,
} from '../../../app/type';
import { Header } from '../../../common/components/ResourceListTypes';
import TableList from '../../../common/components/TableList';
import Avatar from '../../../common/components/Avatar';
import { faPause } from '@fortawesome/free-solid-svg-icons';
import { GenericObject } from '../../../app/type';

let queryTimeout: ReturnType<typeof setTimeout>;

const recurrenceTranslate = (recurrence: string, custom_days?: number) => {
  return {
    week: 'semanalmente',
    month: 'mensualmente',
    bimonth: 'cada dos meses',
    querter: 'trimestralmente',
    semester: 'semestralmente',
    year: 'anualmente',
    custom: `cada ${custom_days} días`,
  }[recurrence];
};

const SubscriptionShow = (): React.ReactElement => {
  const { subscription, company } = useSelector(({ seller }: { seller: SellerState }) => seller);
  const { user } = useSelector(({ session }: { session: SessionState }) => session);
  setDocumentTitle(subscription?.name || 'Suscripciones');
  const isMobile = useMediaQuery(`(max-width:${variables.breakpointMedium})`);
  const { enqueueSnackbar } = useSnackbar();
  const subscriptionURL = `${
    company?.subdomain ? FRONT_URL.replace('app', company?.subdomain) : FRONT_URL
  }/subscriptions/${subscription?.id}${
    subscription?.origin === 'zafepay' ? '' : `?origin=${subscription?.origin}`
  }`;
  const [QRopen, setQROpen] = useState<boolean>(false);
  const [QR, setQR] = useState<string>('');
  const doc = new jsPDF();
  const reloadRef = useRef<{ reloadResource: () => void }>();
  const dispatch = useDispatch();
  const history = useHistory();

  const [email, setEmail] = useState<Email>();
  const [subBuyerState, setSubBuyerState] = useState<SubscriptionBuyer>();
  const [openSendEmail, setOpenSendEmail] = useState<boolean>(false);
  const [openMarkPayment, setOpenMarkPayment] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [paymentTransactions, setPaymentTransactions] = useState<PaymentTransaction[]>([]);
  const [openStatementPopup, setOpenStatementPopup] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<string>();
  const [selectedStatement, setSelectedStatement] = useState<Statement>();
  const [openPausePopup, setOpenPausePopup] = useState<boolean>(false);
  const [openCancelPopup, setOpenCancelPopup] = useState<boolean>(false);
  const [openRetryChargePopup, setOpenRetryChargePopup] = useState<boolean>(false);
  const [updateDop, setUpdateDop] = useState<boolean>(false);
  const [openRefundPopup, setOpenRefundPopup] = useState<boolean>(false);
  const [showProductsHistory, setShowProductsHistory] = useState<boolean>(false);
  const [productHistory, setProductHistory] = useState<ProductHistory[]>();
  const [statementProductHistory, setStatementProductHistory] = useState<ProductHistory[]>([]);
  const [statementDrawerOpen, setStatementDrawerOpen] = useState<boolean>(false);
  const [openAddNoteDrawer, setOpenAddNoteDrawer] = useState<boolean>(false);
  const [notes, setNotes] = useState<Note[]>([]);
  const [noteDescription, setNoteDescription] = useState<string>();
  const [loadingNotes, setLoadingNotes] = useState<boolean>(false);
  const [documentsDrawerOpen, setDocumentsDrawerOpen] = useState<boolean>(false);
  const [subscriptionsPopupOpen, setSubscriptionsPopupOpen] = useState<boolean>(false);
  const [subscriptionsQuery, setSubscriptionsQuery] = useState<string>();
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const [selectedSubscription, setSelectedSubscription] = useState<Subscription>();
  const [changeSubscriptionPopup, setChangeSubscriptionPopup] = useState<boolean>(false);
  const [askExtraData, setAskExtraData] = useState<boolean>(true);
  const [questions, setQuestions] = useState<ExtraField[]>([]);
  const [loadingSubscriptionUpdate, setLoadingSubscriptionUpdate] = useState<boolean>(false);
  const [showChangeSubscriptionForm, setShowChangeSubscriptionForm] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [selectBuyerOpen, setSelectBuyerOpen] = useState<boolean>(false);
  const [buyers, setBuyers] = useState<Buyer[]>([]);
  const [buyersQuery, setBuyersQuery] = useState<string>();
  const [query, setQuery] = useState<string>();
  const [buyerMenuEl, setBuyerMenuEl] = useState(null);
  const openBuyerMenu = (event: any) => setBuyerMenuEl(event?.currentTarget);
  const closeBuyerMenu = () => setBuyerMenuEl(null);
  const access: string = company?.seller?.access || '';
  const resourceRoute = 'subscription';

  const loadBuyers = () => {
    if (company) {
      setLoading(true);
      sellerApi.buyers
        .list(company.id, buyersQuery)
        .then((data: ApiList<Buyer>) => {
          setBuyers([...data.data]);
        })
        .catch(console.error)
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const loadSubscriptions = () => {
    if (company) {
      setLoading(true);
      sellerApi.subscriptions
        .list(company.id, subscriptionsQuery, undefined, {
          status: { subscription: { active: 'activated', inactive: 'deactivated' } },
        })
        .then((data: ApiList<Subscription>) => {
          setSubscriptions([...data.data]);
        })
        .catch(console.error)
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    loadBuyers();
  }, [company, buyersQuery]);

  useEffect(() => {
    loadSubscriptions();
  }, [company, subscriptionsQuery]);

  const onBuyerSubscribe = (buyer: Buyer) => {
    setLoading(true);
    if (subscription?.emit_document) {
      dispatch(setBuyer(buyer));
      history.push(
        `/seller/subscriptions/${subscription?.id}/new_buyer?add_invoice_information=true`
      );
    } else {
      sellerApi.subscriptions
        .subscriptionBuyer(subscription?.id || '', buyer.id)
        .then(() => {
          loadBuyers();
          reloadRef?.current?.reloadResource?.();
        })
        .catch((reason) => {
          if (reason.response.data.message === 'inscriptions_limit_reached') {
            enqueueSnackbar('Límite de inscripciones alcanzado', { variant: 'error' });
          } else enqueueSnackbar('Ocurrió un error al agregar al cliente', { variant: 'error' });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (queryTimeout) clearTimeout(queryTimeout);
    setQuery(event.target.value);

    queryTimeout = setTimeout(() => {
      setBuyersQuery(event.target.value);
    }, 500);
  };

  const handleSubscriptionQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (queryTimeout) clearTimeout(queryTimeout);
    setQuery(event.target.value);

    queryTimeout = setTimeout(() => {
      setSubscriptionsQuery(event.target.value);
    }, 500);
  };

  const startAd = (): React.ReactElement => {
    return (
      <InputAdornment position="start">
        {loading ? <CircularProgress size={18} /> : <SearchIcon />}
      </InputAdornment>
    );
  };

  const onSubscribeBuyer = () => {
    closeBuyerMenu();
    setSelectBuyerOpen(true);
  };

  const onCreateBuyer = () => {
    dispatch(setBuyer(undefined));
    history.push(`/seller/subscriptions/${subscription?.id}/new_buyer`);
  };

  const subscriptionName =
    subscription?.name ||
    subscription?.subscription_products.reduce(
      (prev, curr, index) => prev + `${index === 0 ? '' : ', '}${curr.product.name}`,
      ''
    );

  const onLinkCopy = () => {
    copyToClipboard(subscriptionURL);
    enqueueSnackbar('Enlace copiado', { variant: 'info' });
  };

  useEffect(() => {
    QRCode.toDataURL(subscriptionURL).then((url) => {
      setQR(url);
    });
  }, [subscriptionURL]);

  const showBuyerPage = (buyer?: Buyer) => {
    dispatch(setBuyer(buyer));
    history.push(`/seller/buyers/${buyer?.id}`);
  };

  const deleteSubscriptionBuyer = (subscriptionBuyer?: SubscriptionBuyer) => {
    if (!subscriptionBuyer?.id) return;
    sellerApi.subscriptionBuyers
      .destroySubscriptionBuyer(subscriptionBuyer.id)
      .then(() => {
        enqueueSnackbar('Se eliminó la inscripción', { variant: 'info' });
        reloadRef?.current?.reloadResource?.();
      })
      .catch((error) => {
        if (error.response?.status === 403) {
          enqueueSnackbar('No tienes los permisos necesarios para realizar esta acción. ', {
            variant: 'error',
          });
        } else if (error.response?.status === 400) {
          enqueueSnackbar('No se pudo eliminar la inscripción porque tiene cuotas pagadas', {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('No se pudo eliminar la inscripción', { variant: 'error' });
        }
      });
  };

  const markSubscriptionAsActiveAction = (subscription?: SubscriptionBuyer) => {
    if (!subscription?.id) return;
    if (subscription.status == 'paused') {
      setSubBuyerState(subscription);
      setOpenStatementPopup(true);
    } else markSubscriptionAsActive(subscription);
  };

  const markSubscriptionAsActive = (
    subscription?: SubscriptionBuyer,
    dueDate?: string,
    updateDop?: boolean
  ) => {
    if (!subscription?.id) return;
    sellerApi.subscriptionBuyers
      .markAsActive(subscription.id, dueDate, updateDop)
      .then(() => {
        enqueueSnackbar('Se activó la inscripción', { variant: 'info' });
        reloadRef?.current?.reloadResource?.();
      })
      .catch((reason) => {
        let text = '';
        if (reason.response?.status === 403) {
          text = 'No tienes los permisos necesarios para realizar esta acción. ';
        }
        if (reason.response.data.message === 'invalid_date')
          text = 'La fecha debe ser mayor que hoy';
        if (reason.response.data.message === 'invalid_format')
          text = 'Fecha inválida, inténtalo nuevamente';
        enqueueSnackbar(text || 'No se pudo activar la inscripción, inténtalo más tarde', {
          variant: 'error',
        });
      })
      .finally(() => {
        setOpenStatementPopup(false);
        setSubBuyerState(undefined);
        setSelectedDate(undefined);
        setUpdateDop(false);
      });
  };

  const checkChargeEmail = (subscriptionBuyer?: SubscriptionBuyer) => {
    if (!subscriptionBuyer?.id) return;
    sellerApi.subscriptionBuyers
      .checkChargeEmail(subscriptionBuyer.id)
      .then((data: Email) => {
        setEmail(data);
      })
      .catch(() => {
        setEmail(undefined);
      })
      .finally(() => {
        setOpenSendEmail(true);
        reloadRef?.current?.reloadResource?.();
      });
  };

  const sendChargeEmail = (subscriptionBuyer?: SubscriptionBuyer) => {
    if (!subscriptionBuyer?.id) return;
    sellerApi.subscriptionBuyers
      .sendChargeEmail(subscriptionBuyer.id)
      .then(() => {
        enqueueSnackbar('Se enviará correo de cobro', { variant: 'info' });
      })
      .catch((error) => {
        if (error.response?.status === 403) {
          enqueueSnackbar('No tienes los permisos necesarios para realizar esta acción. ', {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Correo no pudo ser enviado', { variant: 'error' });
        }
      })
      .finally(() => {
        setSubBuyerState(undefined);
      });
  };

  const markSubscriptionAsCanceledAction = (subscriptionBuyer: SubscriptionBuyer) => {
    setOpenCancelPopup(true);
    setSubBuyerState(subscriptionBuyer);
  };

  const markSubscriptionAsCanceled = () => {
    if (!subBuyerState?.id) return;
    setOpenCancelPopup(false);
    sellerApi.subscriptionBuyers
      .markAsCanceled(subBuyerState.id)
      .then(() => {
        enqueueSnackbar('Se canceló la inscripción', { variant: 'info' });
        reloadRef?.current?.reloadResource?.();
      })
      .catch((error) => {
        if (error.response?.status === 403) {
          enqueueSnackbar('No tienes los permisos necesarios para realizar esta acción. ', {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('No se pudo cancelar la inscripción', { variant: 'error' });
        }
      })
      .finally(() => {
        setSubBuyerState(undefined);
      });
  };

  const markSubscriptionAsPausedAction = (subscriptionBuyer: SubscriptionBuyer) => {
    setOpenPausePopup(true);
    setSubBuyerState(subscriptionBuyer);
  };

  const markSubscriptionAsPaused = () => {
    if (!subBuyerState?.id) return;
    setOpenPausePopup(false);
    sellerApi.subscriptionBuyers
      .markAsPaused(subBuyerState.id)
      .then(() => {
        enqueueSnackbar('Se pausó la inscripción', { variant: 'info' });
        reloadRef?.current?.reloadResource?.();
      })
      .catch((error) => {
        if (error.response?.status === 403) {
          enqueueSnackbar('No tienes los permisos necesarios para realizar esta acción. ', {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('No se pudo pausar la inscripción', { variant: 'error' });
        }
      })
      .finally(() => {
        setSubBuyerState(undefined);
      });
  };

  const manualPaymentAction = (statement: Statement) => {
    setSelectedStatement(statement);
    setOpenMarkPayment(true);
  };

  const markStatementAsPaid = (statement?: Statement) => {
    if (!statement?.id) return;
    sellerApi.statements
      .markAsPaid(statement.id)
      .then(() => {
        enqueueSnackbar('Inscripción actualizada', { variant: 'info' });
        reloadRef?.current?.reloadResource?.();
      })
      .catch((error) => {
        if (error.response?.status === 403) {
          enqueueSnackbar('No tienes los permisos necesarios para realizar esta acción. ', {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Inscripción no pudo ser actualizada', { variant: 'error' });
        }
      })
      .finally(() => {
        setSubBuyerState(undefined);
        setSelectedStatement(undefined);
      });
  };

  const unsubscribeCard = (subscriptionBuyer?: SubscriptionBuyer) => {
    if (!subscriptionBuyer) return;
    sellerApi.subscriptionBuyers
      .unsubscribeCard(subscriptionBuyer.id)
      .then(() => {
        enqueueSnackbar('La inscripción no se cobrará automáticamente', { variant: 'success' });
        reloadRef?.current?.reloadResource?.();
      })
      .catch((error) => {
        if (error.response?.status === 403) {
          enqueueSnackbar('No tienes los permisos necesarios para realizar esta acción. ', {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('No se pudo desvincular la tarjeta', { variant: 'error' });
        }
      });
  };

  const refundStatementAction = (statement: Statement) => {
    setOpenRefundPopup(true);
    setSelectedStatement(statement);
  };

  const refundStatement = (status: string) => {
    setOpenRefundPopup(false);
    enqueueSnackbar('Estamos procesando tu solicitud', {
      variant: 'success',
    });
    sellerApi.statements
      .refund(selectedStatement?.id || '', selectedStatement?.group_id, status)
      .then((response) => {
        enqueueSnackbar(
          response.message === 'kushki'
            ? 'La devolución está siendo procesada por el proveedor'
            : status === 'refund'
            ? 'Pago reembolsado exitosamente'
            : 'Solicitud procesada con éxito',
          {
            variant: 'success',
          }
        );
      })
      .catch((reason) => {
        let text = '';
        if (reason.response.status === 403)
          text = 'No tienes los permisos necesarios para realizar esta acción. ';
        else if (reason.response.data.message === 'fintoc not allowed')
          text = 'Fintoc no está habilitado para realizar devoluciones';
        else if (reason.response.data.message === 'expired_time')
          text = 'Han pasado más de 14 días desde que se hizo el pago';
        else if (reason.response.data.message === 'exceed_amount')
          text = 'No tienes saldo suficiente para devolver el monto';
        else if (reason.response.data.message === 'not_credit_card')
          text = 'Solo se pueden hacer devoluciones de tarjetas de crédito';
        else if (reason.response.data.message === 'Klap not allowed')
          text = 'Klap no está habilitado para realizar devoluciones';
        enqueueSnackbar(text || 'No pudimos devolver el pago, inténtalo más tarde', {
          variant: 'error',
        });
      })
      .finally(() => {
        setSelectedStatement(undefined);
      });
  };

  const statementPayemtTransactions = (res: Statement) => {
    setDrawerOpen(true);
    sellerApi.statements
      .paymentTransactions(res.id)
      .then((data) => {
        setPaymentTransactions(data.data);
      })
      .catch(() => {
        enqueueSnackbar('Error al cargar los intentos de cobro de la cuota', {
          variant: 'error',
        });
      });
  };

  const closeDeleteDialog = () => {
    deleteSubscription();
    setDeleteDialogOpen(false);
  };

  const deleteSubscription = () => {
    if (subscription) {
      sellerApi.subscriptions
        .destroy(subscription?.id)
        .then(() => {
          history.push('/seller/subscriptions');
          enqueueSnackbar('Servicio eliminado', { variant: 'success' });
        })
        .catch((error) => {
          if (error.response?.status === 403) {
            enqueueSnackbar('No tienes los permisos necesarios para realizar esta acción. ', {
              variant: 'error',
            });
          } else {
            enqueueSnackbar('La suscripción no puede ser eliminada por tener pagos completados', {
              variant: 'error',
            });
          }
        });
    }
  };

  const statementStatusAction = (statement: Statement) => {
    const hash: { [key: string]: GenericObject[] } = {
      expired: [],
      paid: [{ label: 'Devolver', action: 'refund' }],
      pending: [{ label: 'Cambiar fecha de cobro', action: 'update_due_date' }],
    };

    if (!allowedRole(access, resourceRoute, sellerActions.action)) {
      hash['paid'] = [];
    }
    if (!allowedRole(access, resourceRoute, sellerActions.update)) {
      hash['pending'] = [];
    }

    if (statement.payable_card) hash['expired'] = [{ label: 'Ver intentos', action: 'detail' }];

    if (statement.payable_card && allowedRole(access, resourceRoute, sellerActions.update)) {
      hash['expired'] = [...hash['expired'], { label: 'Reintentar cobro', action: 'retry_charge' }];
    }

    if (statement.has_product_histories)
      hash['paid'] = [...hash['paid'], { label: 'Ver detalle', action: 'product_histories' }];
    if (subscription?.cumulative_debt) {
      hash['pending'] = [
        ...hash['pending'],
        { label: 'Marcar como pagada', action: 'manual_payment' },
      ];
      hash['expired'] = [
        ...hash['expired'],
        { label: 'Marcar como pagada', action: 'manual_payment' },
      ];
    }
    return hash[statement.status as keyof typeof hash] || '';
  };

  const updateStatementDuedateAction = (statement: Statement) => {
    setSubBuyerState(undefined);
    setOpenStatementPopup(true);
    setSelectedStatement(statement);
  };

  const updateStatementDuedate = () => {
    setOpenStatementPopup(false);
    sellerApi.statements
      .updateDueDate(selectedStatement?.id || '', selectedDate || '', updateDop)
      .then(() => {
        enqueueSnackbar('Cuota actualizada correctamente', {
          variant: 'success',
        });
        reloadRef?.current?.reloadResource?.();
      })
      .catch((reason) => {
        let text = '';
        if (reason.response.data.message === 'invalid_date')
          text = 'La fecha debe ser mayor que hoy';
        if (reason.response.data.message === 'invalid_format')
          text = 'Fecha inválida, inténtalo nuevamente';
        enqueueSnackbar(text || 'No pudimos actualizar la fecha, inténtalo más tarde', {
          variant: 'error',
        });
      })
      .finally(() => {
        setSelectedDate(undefined);
        setSelectedStatement(undefined);
        setUpdateDop(false);
      });
  };

  const retryChargeAction = (statement: Statement) => {
    setSelectedStatement(statement);
    setOpenRetryChargePopup(true);
  };

  const retryCharge = () => {
    setOpenRetryChargePopup(false);
    enqueueSnackbar('Estamos procesando el cobro', {
      variant: 'success',
    });
    sellerApi.statements
      .retryCharge(selectedStatement?.id || '')
      .then(() => {
        enqueueSnackbar('Intento de cobro realizado exitosamente', {
          variant: 'success',
        });
        reloadRef?.current?.reloadResource?.();
      })
      .catch((reason) => {
        let text = '';
        if (reason.response.data.message === 'error_collecting')
          text = 'Intento de cobro rechazado';
        if (reason.response.data.message === 'daily_attempts_exceeded')
          text = 'Máximo un intento diario, inténtalo nuevamente otro día';
        if (reason.response.data.message === 'max_attempts_exceeded')
          text = 'Máximo intentos de cobros superado';
        enqueueSnackbar(text || 'No pudimos relizar el intento de cobro, inténtalo más tarde', {
          variant: 'error',
        });
      })
      .finally(() => {
        setSelectedStatement(undefined);
      });
  };

  const productsHistory = () => {
    if (subscription) {
      sellerApi.subscriptions
        .productsHistory(subscription?.id)
        .then((data) => {
          setProductHistory(data.data);
        })
        .catch(() => {
          enqueueSnackbar('No pudimos cargar el historial de prodcutos, intenta nuevamente', {
            variant: 'error',
          });
        });
    }
  };

  const statementProductHistories = (res: Statement) => {
    setStatementDrawerOpen(true);
    sellerApi.statements
      .productsHistory(res.id)
      .then((data) => {
        setStatementProductHistory(data.data);
      })
      .catch(() => {
        enqueueSnackbar('Error al cargar los detalles de la cuota', {
          variant: 'error',
        });
      });
  };

  const addNoteAction = (subscriptionBuyer: SubscriptionBuyer) => {
    subscriptionBuyerNotes(subscriptionBuyer.id);
    setSubBuyerState(subscriptionBuyer);
    setOpenAddNoteDrawer(true);
  };

  const subscriptionBuyerNotes = (subscriptionBuyerId: string) => {
    setLoadingNotes(true);
    sellerApi.subscriptionBuyers
      .notes(subscriptionBuyerId)
      .then((data: ApiList<Note>) => {
        setNotes(data.data);
      })
      .catch(() => {
        enqueueSnackbar('Error al cargar las notas de la inscripción', {
          variant: 'error',
        });
      })
      .finally(() => {
        setLoadingNotes(false);
      });
  };

  const addNote = () => {
    if (noteDescription && noteDescription.length > 0) {
      sellerApi.subscriptionBuyers
        .addNote(subBuyerState?.id || '', noteDescription)
        .then((data: ApiList<Note>) => {
          setNotes(data.data);
          enqueueSnackbar('Nota agregada exitosamente', {
            variant: 'success',
          });
          setNoteDescription('');
        })
        .catch(() => {
          enqueueSnackbar('Error al crear la nota de la inscripción, intenta nuevamente', {
            variant: 'error',
          });
        });
    }
  };

  const deleteNote = (noteId: string) => {
    sellerApi.subscriptionBuyers
      .deleteNote(subBuyerState?.id || '', noteId)
      .then((data: ApiList<Note>) => {
        setNotes(data.data);
        enqueueSnackbar('Nota eliminada exitosamente', {
          variant: 'success',
        });
      })
      .catch(() => {
        enqueueSnackbar('Error al eliminar la nota de la inscripción, intenta nuevamente', {
          variant: 'error',
        });
      });
  };

  const statementInoviceDocuments = (statement: Statement) => {
    setSelectedStatement(statement);
    setDocumentsDrawerOpen(true);
  };

  const generateDocument = (invoiceDocumentId: string) => {
    sellerApi.statements
      .retryInvoiceDocument(selectedStatement?.id || '', invoiceDocumentId)
      .then(() => {
        setDocumentsDrawerOpen(false);
        reloadRef?.current?.reloadResource?.();
        enqueueSnackbar('Se intentará crear el documento', {
          variant: 'success',
        });
      })
      .catch(() => {
        enqueueSnackbar('Error al cargar los detalles de la cuota', {
          variant: 'error',
        });
      });
  };

  const changeSubscriptionAction = (subscriptionBuyer: SubscriptionBuyer) => {
    setSubBuyerState(subscriptionBuyer);
    setSubscriptionsPopupOpen(true);
  };

  const onSubscriptionChange = (subscription: Subscription) => {
    setSelectedSubscription(subscription);
    setChangeSubscriptionPopup(true);
  };

  const handleChangeSubscription = () => {
    if (
      askExtraData &&
      ((selectedSubscription?.extra_fields &&
        Object.keys(selectedSubscription?.extra_fields).length > 0) ||
        selectedSubscription?.ask_shipping_address ||
        selectedSubscription?.emit_document)
    ) {
      setLoadingSubscriptionUpdate(true);
      sellerApi.subscriptions
        .questions(selectedSubscription?.id || '')
        .then((data) => {
          setQuestions(data.data);
        })
        .finally(() => {
          formRef.current?.reset();
          setAskExtraData(false);
          setLoadingSubscriptionUpdate(false);
        });
      setShowChangeSubscriptionForm(true);
    } else {
      handleChangeSubscriptionSuccess({});
    }
  };

  const handleChangeSubscriptionSuccess = async (formData: any) => {
    setLoading(true);
    sellerApi.subscriptionBuyers
      .changeSubscriptionBuyer(subBuyerState?.id || '', selectedSubscription?.id || '', formData)
      .then((data: ApiObject<Subscription>) => {
        dispatch(setSubscription(data.data));
        history.push(`/seller/subscriptions/${data.data.id}`);
        enqueueSnackbar('Cambio realizado exitosamente', {
          variant: 'success',
        });
      })
      .catch(() => {
        enqueueSnackbar('Ocurrió un error al modificar la inscripción, intenta nuevamente', {
          variant: 'error',
        });
      })
      .finally(() => {
        setChangeSubscriptionPopup(false);
        setSubscriptionsPopupOpen(false);
        setShowChangeSubscriptionForm(false);
        setLoading(false);
        setAskExtraData(true);
        setQuestions([]);
      });
  };

  useEffect(() => {
    if (!documentsDrawerOpen) setSelectedStatement(undefined);
  }, [documentsDrawerOpen]);

  const et_date = dateByString(subscription?.expiration_time || '');

  const boxes = (width?: number) => [
    <InfoBox
      key={1}
      title="TOTAL RECAUDADO DEL MES"
      data={formatCurrency(subscription?.total_month_gathered)}
      kind="info2"
      style={styles.firstInfoContainer}
      icon={<FontAwesomeIcon icon={faBagShopping} className="icon" />}
      width={width}
    />,
    <InfoBox
      key={2}
      title="TOTAL DEUDA DEL MES"
      data={formatCurrency(subscription?.total_month_debt)}
      kind="info2"
      style={styles.secondInfoContainer}
      icon={<FontAwesomeIcon icon={faCreditCard} className="icon" />}
      width={width}
    />,
    <InfoBox
      key={3}
      title="NÚMERO DE CLIENTES"
      data={subscription?.total_clients || 0}
      kind="info2"
      style={styles.thirdInfoContainer}
      icon={<FontAwesomeIcon icon={faUsers} className="icon" />}
      width={width}
    />,
    <InfoBox
      key={4}
      title="Agregar Cliente"
      function={openBuyerMenu}
      kind="link"
      image="https://storage.googleapis.com/onlypays-public/assets/images/girl%20and%20boy%20working%20together%20in%20front%20of%20laptop.svg"
      style={styles.linkContainer}
      width={width}
      action={sellerActions.create}
      route="buyer"
      access={access}
    />,
  ];

  useEffect(() => {
    if (!changeSubscriptionPopup) {
      setQuestions([]);
      setShowChangeSubscriptionForm(false);
      setAskExtraData(true);
    }
  }, [changeSubscriptionPopup]);

  return (
    <>
      <div className={styles.start}>
        <Typography variant={isMobile ? 'h5' : 'h4'}>
          <CopyIcon />
          <span className={styles.startTitle}>Suscripciones</span>
        </Typography>
        <Typography variant="body1">
          Las <b>suscripciones</b> se cobran periódicamente.
        </Typography>
      </div>

      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item xs={12}>
          <Paper className={cStyles.infoPaper}>
            {/* HEADER */}
            <Grid container>
              <Grid item xs={12}>
                <div className={cStyles.paperHeader}>
                  <Avatar
                    className={cStyles.paperHeaderAvatar}
                    text={subscription?.name}
                    img={subscription?.image}
                    context="product"
                  />
                  <div className={cStyles.paperHeaderContent}>
                    <Typography variant={isMobile ? 'h6' : 'h5'}>
                      <b>{subscriptionName}</b>
                    </Typography>
                    {!isMobile && (
                      <Typography variant="subtitle1">{subscription?.description}</Typography>
                    )}
                  </div>
                  <div className={cStyles.paperHeaderActions}>
                    {allowedRole(access, resourceRoute, sellerActions.update) && (
                      <div className={`${cStyles.baseAction} ${cStyles.editAction}`}>
                        <IconButton
                          size="medium"
                          component={Link}
                          to={`/seller/subscriptions/${subscription?.id}/edit`}
                          className={`${cStyles.icon} ${cStyles.editIcon}`}
                        >
                          {<EditIcon />}
                        </IconButton>
                        <Typography variant="body2">Editar</Typography>
                      </div>
                    )}
                    {allowedRole(access, resourceRoute, sellerActions.destroy) && (
                      <div className={`${cStyles.baseAction} ${cStyles.deleteAction}`}>
                        <IconButton
                          size="medium"
                          onClick={() => setDeleteDialogOpen(true)}
                          className={`${cStyles.icon} ${cStyles.deleteIcon}`}
                        >
                          {<TrashIcon />}
                        </IconButton>
                        <Typography variant="body2">Eliminar</Typography>
                      </div>
                    )}
                  </div>
                </div>
              </Grid>
            </Grid>
            {/* INFO */}
            <Grid container className={cStyles.infoContainer}>
              <Grid item xs={6} md={4}>
                <Typography variant="caption">Recurrencia:</Typography>
                <Typography variant="h6">
                  <b>{RECURRENCE_ES[subscription?.recurrence || '']}</b>
                </Typography>
              </Grid>
              {subscription?.custom_recurrence_amount &&
              subscription?.custom_recurrence_amount !== 0 ? (
                <Grid item xs={6} md={4}>
                  <Typography variant="caption">Recurrencia personalizada:</Typography>
                  <Typography variant="h6">
                    <b>Cada {subscription?.custom_recurrence_amount} días</b>
                  </Typography>
                </Grid>
              ) : (
                <></>
              )}
              <Grid item xs={6} md={4}>
                <Typography variant="caption">Valor:</Typography>
                <Typography variant="h6">
                  <b>{formatCurrency(subscription?.render_amount, subscription?.currency)}</b>
                </Typography>
              </Grid>
              <Grid item xs={6} md={4}>
                <Typography variant="caption">Fecha de expiración:</Typography>
                <Typography variant="h6">
                  {subscription?.expiration_time
                    ? `${moment(subscription.expiration_time, 'YYYY-MM-DD HH:mm:ss Z').format(
                        'DD/MM/YYYY HH:mm'
                      )} ${new Date() > et_date ? ' (Link expirado)' : ''}`
                    : 'Sin fecha de expiración'}
                </Typography>
              </Grid>
              {subscription?.periods ? (
                <Grid item xs={6} md={4}>
                  <Typography variant="caption">Duración:</Typography>
                  <Typography variant="h6">
                    <b>{subscription?.periods}</b>
                  </Typography>
                </Grid>
              ) : null}
              {subscription?.dop ? (
                <Grid item xs={6} md={4}>
                  <Typography variant="caption">Día de cobro:</Typography>
                  <Typography variant="h6">
                    <b>
                      {subscription?.recurrence == 'week'
                        ? WEEKDAYS_ES[subscription?.dop]
                        : subscription?.dop}
                    </b>
                  </Typography>
                </Grid>
              ) : null}
              {subscription?.has_apportion ? (
                <Grid item xs={6} md={4}>
                  <Typography variant="caption">Prorrateo:</Typography>
                  <Typography variant="h6">
                    <b>{subscription?.has_apportion ? 'Tiene prorrateo' : 'No tiene prorrateo'}</b>
                  </Typography>
                </Grid>
              ) : null}
              {subscription?.start_at ? (
                <Grid item xs={6} md={4}>
                  <Typography variant="caption">Fecha de primer cobro:</Typography>
                  <Typography variant="h6">
                    <b>{subscription?.start_at}</b>
                  </Typography>
                </Grid>
              ) : null}
              {subscription?.cumulative_debt ? (
                <Grid item xs={6} md={4}>
                  <Typography variant="caption">¿Permite acumular deuda?:</Typography>
                  <Typography variant="h6">
                    <b>{subscription?.cumulative_debt ? 'Sí acumula deuda' : 'No acumula deuda'}</b>
                  </Typography>
                </Grid>
              ) : null}
              {subscription?.inscriptions_limit ? (
                <Grid item xs={6} md={4}>
                  <Typography variant="caption">Límite de inscripciones:</Typography>
                  <Typography variant="h6">
                    <b>{subscription?.inscriptions_limit}</b>
                  </Typography>
                </Grid>
              ) : null}
              {subscription?.cancel_debt_quantity ? (
                <Grid item xs={6} md={4}>
                  <Typography variant="caption">
                    Cancelar inscripciones abandonadas después de:
                  </Typography>
                  <Typography variant="h6">
                    <b>
                      {subscription?.cancel_debt_quantity}{' '}
                      {subscription.cancel_debt_unit &&
                        (subscription?.cancel_debt_quantity > 1
                          ? PLURAL_DATE_UNITS_ES[subscription.cancel_debt_unit]
                          : DATE_UNITS_ES[subscription.cancel_debt_unit])}
                    </b>
                  </Typography>
                </Grid>
              ) : null}
              {subscription?.ask_shipping_address ? (
                <Grid item xs={6} md={4}>
                  <Typography variant="caption">¿Pide dirección de despacho?</Typography>
                  <Typography variant="h6">
                    <b>Sí</b>
                  </Typography>
                </Grid>
              ) : null}
              {subscription?.terms_and_conditions_url ? (
                <Grid item xs={6} md={4}>
                  <Typography variant="caption">¿Pide aceptar términos y condiciones?</Typography>
                  <Typography variant="h6" style={{ width: 'min-content' }}>
                    Sí&nbsp;
                    <span className={styles.linkTo}>
                      <a
                        href={subscription?.terms_and_conditions_url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        (ver) <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="icon" />
                      </a>
                    </span>
                  </Typography>
                </Grid>
              ) : null}
              {subscription?.emit_document ? (
                <Grid item xs={6} md={4}>
                  <Typography variant="caption">¿Emite documento tributario?</Typography>
                  <Typography variant="h6">
                    <b>Sí</b>
                  </Typography>
                </Grid>
              ) : null}
              <Grid item xs={12}>
                <Typography variant="caption">Datos a pedir al cliente:</Typography>
              </Grid>
              <Grid container spacing={1} mb={1} mt={0.5}>
                <Grid item>
                  <Chip label="Nombre" />
                </Grid>
                <Grid item>
                  <Chip label="Email" />
                </Grid>
                {subscription?.default_extra_fields
                  ? Object.keys(subscription?.default_extra_fields).map((key: string) => {
                      return subscription?.default_extra_fields ? (
                        <Grid item key={key}>
                          <Chip label={subscription?.default_extra_fields?.[key]} />
                        </Grid>
                      ) : null;
                    })
                  : null}
                {subscription?.extra_fields
                  ? Object.keys(subscription?.extra_fields).map((key: string) => {
                      return subscription?.extra_fields ? (
                        <Grid item key={key}>
                          <Chip label={subscription?.extra_fields?.[key]} />
                        </Grid>
                      ) : null;
                    })
                  : null}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption">Enlace de suscripción:</Typography>
                <div className={cStyles.linkContainer}>
                  <IconButton
                    size="medium"
                    disabled={loading}
                    className={cStyles.copyIcon}
                    onClick={onLinkCopy}
                  >
                    {loading ? <CircularProgress size={20} /> : <FontAwesomeIcon icon={faCopy} />}
                  </IconButton>
                  <IconButton
                    size="medium"
                    disabled={loading}
                    className={cStyles.QRIcon}
                    onClick={() => setQROpen(true)}
                  >
                    {loading ? <CircularProgress size={20} /> : <FontAwesomeIcon icon={faQrcode} />}
                  </IconButton>
                  <Typography variant="h6">
                    <Link
                      to={`/subscriptions/${subscription?.id}${
                        subscription?.origin === 'zafepay' ? '' : `?origin=${subscription?.origin}`
                      }`}
                    >
                      {subscriptionURL}
                    </Link>
                  </Typography>
                </div>
              </Grid>

              {subscription?.subscription_products.length === 1 &&
              subscription?.subscription_products[0].product.kind !== 'common' ? null : (
                <Grid container className={styles.productsGrid}>
                  <Typography className={styles.text}>Resumen Productos</Typography>
                  <Grid item container xs={12} className={styles.header}>
                    <Grid item xs={4}>
                      <Typography>Producto</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography>Cantidad</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography>Total</Typography>
                    </Grid>
                  </Grid>
                  {subscription?.subscription_products.map((subscriptionProduct) => (
                    <Grid
                      item
                      container
                      xs={12}
                      key={subscriptionProduct.id}
                      className={styles.row}
                    >
                      <Grid item xs={4}>
                        <Typography>{subscriptionProduct.product.name}</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography>{subscriptionProduct.quantity}</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography>
                          {formatCurrency(
                            subscriptionProduct.value,
                            subscriptionProduct.product.currency
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              )}
              {subscription?.has_product_histories && (
                <div className={styles.accordionContainer}>
                  <Accordion
                    expanded={showProductsHistory}
                    onChange={() => {
                      productsHistory();
                      setShowProductsHistory(!showProductsHistory);
                    }}
                    sx={{ marginBottom: '10px', marginTop: '10px' }}
                    className={styles.accordion}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography>Historial productos</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {productHistory?.map((ph) => (
                        <div key={ph.id}>
                          <Typography mt={1}>
                            <li>{ph.created_at}</li>
                          </Typography>
                          <Grid container className={`${styles.productsGrid} ${styles.topZero}`}>
                            <Grid item container xs={12} className={styles.header}>
                              <Grid item xs={3}>
                                <Typography>Producto</Typography>
                              </Grid>
                              <Grid item xs={3}>
                                <Typography>Cantidad</Typography>
                              </Grid>
                              <Grid item xs={3}>
                                <Typography>Total</Typography>
                              </Grid>
                              <Grid item xs={3}>
                                <Typography>Suscrito</Typography>
                              </Grid>
                            </Grid>
                            {Object.keys(ph.subscription_products).map((sp_id) => (
                              <Grid item container xs={12} key={sp_id} className={styles.row}>
                                <Grid item xs={3}>
                                  <Typography>{ph.subscription_products[sp_id].name}</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                  <Typography>
                                    {ph.subscription_products[sp_id].quantity}
                                  </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                  <Typography>
                                    {formatCurrency(ph.subscription_products[sp_id].value)}
                                  </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                  <Typography>
                                    {ph.subscription_products[sp_id].subscribed ? 'Sí' : 'No'}
                                  </Typography>
                                </Grid>
                              </Grid>
                            ))}
                          </Grid>
                        </div>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                </div>
              )}
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      {allowedRole(access, 'buyer', sellerActions.create) ? (
        isMobile ? (
          <Button className={styles.toButton} onClick={openBuyerMenu} variant="contained">
            Agregar Cliente
          </Button>
        ) : null
      ) : null}

      {isMobile ? (
        <Stepper assets={boxes(12).slice(0, -1)} />
      ) : (
        <Grid container className={styles.containerGrid} flexWrap={'nowrap'}>
          {boxes().map((box) => box)}
        </Grid>
      )}

      <ResourceList
        title="Clientes"
        getResourceList={sellerApi.subscriptions.subscriptionBuyers}
        excelDownloadMethod={(query, parentId, filterParams) =>
          sellerApi.subscriptions.generateSubscriptionBuyersExcel(
            'subscription',
            parentId,
            query,
            filterParams
          )
        }
        queryFields="Nombre, correo"
        resourceParent={subscription}
        innerRef={reloadRef}
        listHeaders={[
          { key: 'name', label: 'Nombre' },
          { key: 'email', label: 'Correo' },
          { key: 'subscription_date', label: 'Fecha de activación' },
          { key: 'subscription_status', label: 'Estado inscripción' },
          { key: 'statement_status', label: 'Estado pago' },
          { key: 'statement_date', label: 'Fecha de vencimiento' },
          { key: 'completed_payments', label: 'Cuotas pagadas' },
        ]}
        listMobileHeaders={[
          { key: 'name' },
          { key: 'subscription_status' },
          { key: 'statement_date' },
        ]}
        listColumns={{
          name: (res: SubscriptionBuyer) => res.buyer?.name,
          email: (res: SubscriptionBuyer) => res.buyer?.email,
          subscription_date: (res: SubscriptionBuyer) => res.activated_at || '-',
          subscription_status: (res: SubscriptionBuyer) => (
            <StatusLabel type="subscriptionBuyer" status={res.status} />
          ),
          statement_status: (res: SubscriptionBuyer) =>
            res.status !== 'pending' && res.current_statement ? (
              <StatusLabel
                type="statement"
                status={checkPaymentStatus(res?.current_statement, res) || 'pending'}
              />
            ) : undefined,
          statement_date: (res: SubscriptionBuyer) =>
            res.status !== 'pending'
              ? res.current_statement?.due_date || res.current_statement?.updated_at
              : undefined,
          completed_payments: (res: SubscriptionBuyer) => res.completed_payments,
          ...Object.keys(subscription?.all_extra_fields || {})
            .map((key) => {
              return {
                [key]: (res: SubscriptionBuyer) => res.extra_fields?.[key] || '-',
              };
            })
            .reduce((prev, curr) => {
              return { ...prev, ...curr };
            }, {}),
        }}
        listActionsHeaders={(res?: SubscriptionBuyer) => {
          if (res) {
            return [
              allowedRole(access, resourceRoute, sellerActions.read)
                ? { key: 'show', label: 'Ver cliente', icon: faEye }
                : null,

              allowedRole(access, resourceRoute, sellerActions.action) &&
              ['pending', 'canceled', 'paused'].includes(res.status)
                ? {
                    key: 'activate_subscription',
                    label: 'Activar inscripción',
                    icon: faPlusSquare,
                  }
                : null,
              allowedRole(access, resourceRoute, sellerActions.action) &&
              ['active', 'indebt'].includes(res.status)
                ? {
                    key: 'collect',
                    label: 'Enviar correo de cobro',
                    icon: faPaperPlane,
                  }
                : null,
              allowedRole(access, resourceRoute, sellerActions.action) &&
              ['active', 'indebt'].includes(res.status)
                ? {
                    key: 'whatsapp_link',
                    label: 'Copiar link Whatsapp',
                    icon: faComment,
                  }
                : null,
              allowedRole(access, resourceRoute, sellerActions.action) &&
              ['active', 'indebt'].includes(res.status) &&
              !subscription?.cumulative_debt
                ? {
                    key: 'manual_payment',
                    label: 'Marcar como pagada',
                    icon: faMoneyCheck,
                  }
                : null,
              allowedRole(access, resourceRoute, sellerActions.action) &&
              res.card &&
              ['active', 'indebt'].includes(res.status)
                ? {
                    key: 'unsubscribe_card',
                    label: 'Eliminar Pago Automático',
                    icon: faUserXmark,
                  }
                : null,
              allowedRole(access, resourceRoute, sellerActions.action)
                ? { key: 'add_note', label: 'Notas', icon: faFilePen }
                : null,
              allowedRole(access, resourceRoute, sellerActions.update) &&
              ['active', 'indebt', 'paused', 'canceled'].includes(res.status)
                ? {
                    key: 'change_subscription',
                    label: 'Cambiar de suscripción',
                    icon: faRepeat,
                  }
                : null,
              { key: 'add_note', label: 'Notas', icon: faFilePen },
              allowedRole(access, resourceRoute, sellerActions.update) &&
              ['active', 'indebt'].includes(res.status)
                ? {
                    key: 'pause_subscription_buyer',
                    label: 'Pausar inscripción',
                    icon: faPause,
                    color: 'error',
                  }
                : null,
              allowedRole(access, resourceRoute, sellerActions.destroy) &&
              ['active', 'indebt', 'paused'].includes(res.status)
                ? {
                    key: 'cancel_subscription_buyer',
                    label: 'Cancelar inscripción',
                    icon: faMinusSquare,
                    color: 'error',
                  }
                : null,
              allowedRole(access, resourceRoute, sellerActions.destroy) &&
              ['pending', 'canceled', 'finished', 'indebt', 'paused'].includes(res.status)
                ? {
                    key: 'destroy_subscription_buyer',
                    label: 'Eliminar inscripción',
                    icon: faTrashCan,
                    color: 'error',
                  }
                : null,
            ];
          }
          return [null];
        }}
        listActions={{
          show: (res: SubscriptionBuyer) => showBuyerPage(res.buyer),
          destroy_subscription_buyer: (res: SubscriptionBuyer) => deleteSubscriptionBuyer(res),
          activate_subscription: (res: SubscriptionBuyer) => markSubscriptionAsActiveAction(res),
          collect: (res: SubscriptionBuyer) => {
            setSubBuyerState(res);
            checkChargeEmail(res);
          },
          cancel_subscription_buyer: (res: SubscriptionBuyer) =>
            markSubscriptionAsCanceledAction(res),
          pause_subscription_buyer: (res: SubscriptionBuyer) => markSubscriptionAsPausedAction(res),
          manual_payment: (res: SubscriptionBuyer) => {
            setSubBuyerState(res);
            manualPaymentAction(res.current_statement);
          },
          unsubscribe_card: (res: SubscriptionBuyer) => unsubscribeCard(res),
          whatsapp_link: (res: SubscriptionBuyer) => {
            navigator.clipboard.writeText(
              `${
                company?.subdomain ? FRONT_URL.replace('app', company?.subdomain) : FRONT_URL
              }/subscription_buyers/${res.id}`
            );
            enqueueSnackbar('Enlace copiado', { variant: 'info' });
          },
          add_note: (res: SubscriptionBuyer) => addNoteAction(res),
          change_subscription: (res: SubscriptionBuyer) => changeSubscriptionAction(res),
        }}
        chips={Object.keys(subscription?.all_extra_fields || {}).reduce(
          (prev: Header[], curr: string) => {
            if (!subscription?.all_extra_fields[curr]) return prev;
            const hash = { key: curr, label: subscription?.all_extra_fields[curr] };
            return [...prev, hash];
          },
          []
        )}
        getCollapseResources={sellerApi.subscriptionBuyers.statements}
        listCollapseHeaders={[
          { key: 'payment_date', label: 'Fecha de Pago' },
          { key: 'due_date', label: 'Fecha de Vencimiento' },
          { key: 'status', label: 'Estado' },
          { key: 'amount', label: 'Monto' },
          { key: 'payment_method', label: 'Método de Pago' },
          { key: 'invoice_documents', label: 'Documento' },
        ]}
        listCollapseColumns={{
          payment_date: (res: Statement) => res.payment_date || '-',
          due_date: (res: Statement) => res.due_date,
          status: (res: Statement) => <StatusLabel type="statement" status={res.status} />,
          amount: (res: Statement) =>
            `${formatCurrency(res.amount)} ${
              res.discount ? `(-${formatCurrency(res.discount)})` : ''
            }`,
          payment_method: (res: Statement) => (
            <span className={styles.noWrap}>{res.payment_type_translated || '-'}</span>
          ),
          invoice_documents: (res: Statement) =>
            res.invoice_documents.length > 0 ? (
              <Typography
                onClick={() => statementInoviceDocuments(res)}
                sx={{ cursor: 'pointer' }}
                fontSize={14}
              >
                <u>Ver</u>
              </Typography>
            ) : (
              '-'
            ),
        }}
        listMobileCollapseHeaders={[
          { key: 'due_date', label: 'Fecha de Vencimiento' },
          { key: 'status', label: 'Estado' },
          { key: 'payment_method', label: 'Método de Pago' },
        ]}
        filtersKey="subscriptions_show"
        listCollapseActionsHeaders={(res?: Statement) => {
          if (res) {
            return ['Fintoc', 'ExternalTransaction'].includes(res.payment_type || 'none') ||
              ['refunded', 'processing_payment', 'processing_refund', 'refunded_manually'].includes(
                res.status
              )
              ? [null]
              : statementStatusAction(res).map((resp) => ({
                  key: resp.action,
                  label: resp.label,
                }));
          }
          return [null];
        }}
        listCollapseActions={{
          refund: (res: Statement) => refundStatementAction(res),
          detail: (res: Statement) => statementPayemtTransactions(res),
          update_due_date: (res: Statement) => updateStatementDuedateAction(res),
          retry_charge: (res: Statement) => retryChargeAction(res),
          product_histories: (res: Statement) => statementProductHistories(res),
          manual_payment: (res: Statement) => manualPaymentAction(res),
        }}
        actionsTitle="Acciones Inscripción"
        collapseActionsTitle="Acciones Cuota"
      />

      {/* DIALOGS */}
      <PopUp
        state={{ open: QRopen, setOpen: setQROpen }}
        title={
          <>
            <Typography variant="h5" align="center">
              {subscription?.name}
            </Typography>
            <Typography variant="subtitle1">{company?.name}</Typography>
          </>
        }
        content={<img src={QR}></img>}
        extraActions={[
          <Button
            key={1}
            size="large"
            variant="outlined"
            color="primary"
            onClick={() => setQROpen(false)}
          >
            <b>Cancelar</b>
          </Button>,
          <Button
            key={2}
            size="large"
            variant="contained"
            color="primary"
            onClick={() => {
              doc.setFontSize(25);
              doc.text(subscription?.name || '', 105, 25, { align: 'center' });
              doc.text(company?.name || '', 105, 45, { align: 'center' });
              doc.addImage(QR, 'JPEG', 5, 50, 200, 200);
              doc.save(`${subscription?.name}.pdf`);
            }}
          >
            <b>Descargar</b>
          </Button>,
        ]}
      />

      <PopUp
        state={{ open: openSendEmail, setOpen: setOpenSendEmail }}
        content={
          <>
            <Typography sx={{ marginTop: '20px' }} variant="body1" align="center">
              Se enviará un mail con el link de pago al cliente. Se cobrará la cuota con fecha de
              vencimiento: {subBuyerState?.current_statement?.due_date}
            </Typography>
            {email ? (
              <Typography sx={{ marginTop: '20px' }} variant="body1" align="center">
                Ya enviaste un correo cobrando este pago. El último correo que le enviaste fue el{' '}
                {parseDatetime(email.sent_at) || ''}. Recuerda que solo puedes enviar el correo de
                pago cada 30 minutos.
              </Typography>
            ) : null}
          </>
        }
        extraActions={[
          <Button onClick={() => setOpenSendEmail(false)} variant="outlined" key={2}>
            Cancelar
          </Button>,
          <Button
            onClick={() => {
              sendChargeEmail(subBuyerState);
              setOpenSendEmail(false);
            }}
            color="info"
            variant="contained"
            key={1}
          >
            Enviar
          </Button>,
        ]}
      />
      <PopUp
        state={{ open: openMarkPayment, setOpen: setOpenMarkPayment }}
        title={
          <Typography variant="h6" align="center">
            Advertencia
          </Typography>
        }
        content={
          <>
            <Typography sx={{ marginTop: '20px' }} variant="body1" align="center">
              Se marcará como pagada la cuota de{' '}
              <b>
                {subBuyerState
                  ? `${subBuyerState.buyer.name}${subBuyerState.buyer.last_name || ''}`
                  : `${selectedStatement?.buyer?.name}${selectedStatement?.buyer?.last_name || ''}`}
              </b>{' '}
              con fecha de vencimiento <b>{selectedStatement?.due_date}</b>. Esta acción es
              irreversible ¿Estás seguro de continuar?
            </Typography>
          </>
        }
        extraActions={[
          <Button onClick={() => setOpenMarkPayment(false)} variant="outlined" key={2}>
            Cancelar
          </Button>,
          <Button
            onClick={() => {
              markStatementAsPaid(selectedStatement);
              setOpenMarkPayment(false);
            }}
            color="info"
            variant="contained"
            key={1}
          >
            Continuar
          </Button>,
        ]}
      />

      <PopUp
        state={{ open: deleteDialogOpen, setOpen: setDeleteDialogOpen }}
        title={
          <Typography variant="h6" align="center">
            Advertencia
          </Typography>
        }
        content={
          <Typography sx={{ marginTop: '20px' }} variant="body1" align="center">
            Se eliminará la suscripción. Todas las inscripciones a esta suscripción se eliminarán,
            incluidas todas las cuotas que no se han pagado. Esta acción es irreversible. ¿Quieres
            continuar?
          </Typography>
        }
        extraActions={[
          <Button onClick={() => setDeleteDialogOpen(false)} variant="outlined" key={2}>
            No
          </Button>,
          <Button onClick={closeDeleteDialog} color="error" variant="contained" key={1}>
            Si
          </Button>,
        ]}
      />

      <PopUp
        state={{ open: openStatementPopup, setOpen: setOpenStatementPopup }}
        title={
          <Typography variant="h6" align="center">
            {subBuyerState ? 'Activar inscripción' : 'Editar fecha de cobro'}
          </Typography>
        }
        content={
          <Fragment>
            <Typography sx={{ marginTop: '20px' }} variant="body1" align="center">
              {subBuyerState
                ? 'Elige la fecha que quieres realizar el primer cobro'
                : 'Elige la nueva fecha que quieres cobrar la cuota'}
            </Typography>
            <TextField
              label="Fecha de cobro"
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
              id="due_date"
              type="date"
              autoComplete="due_date"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              sx={{ marginTop: '30px', marginBottom: '10px' }}
            />
            <Typography sx={{ marginTop: '20px' }} variant="body1" align="center">
              ¿Quieres que esta nueva fecha modifique el día de cobro solo de esta cuota o de esta y
              las futuras?
            </Typography>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={updateDop}
                onChange={(e) => setUpdateDop(e.target.value == 'true')}
              >
                <FormControlLabel value="false" control={<Radio />} label="Solo esta cuota" />
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="Esta cuota y las futuras"
                />
              </RadioGroup>
            </FormControl>
            {selectedDate && (
              <Typography sx={{ marginTop: '20px', fontSize: '14px' }} align="center">
                * Esta cuota se cobrará el{' '}
                {selectedDate.replace(/^(\d{4})-(\d{2})-(\d{2})$/, '$3-$2-$1')} y las futuras{' '}
                {updateDop
                  ? `${
                      selectedStatement
                        ? recurrenceTranslate(
                            selectedStatement.payable_recurrence || '',
                            selectedStatement.payable_recurrence_days
                          )
                        : recurrenceTranslate(
                            subBuyerState?.subscription_recurrence || '',
                            subBuyerState?.subscription_recurrence_days || 0
                          )
                    } desde esta nueva fecha.`
                  : `seguirán el flujo normal.`}
              </Typography>
            )}
          </Fragment>
        }
        extraActions={[
          <Button onClick={() => setOpenStatementPopup(false)} key={1} variant="outlined">
            Cancelar
          </Button>,
          <Button
            onClick={() => {
              subBuyerState
                ? markSubscriptionAsActive(subBuyerState, selectedDate, updateDop)
                : updateStatementDuedate();
            }}
            variant="contained"
            key={2}
          >
            {subBuyerState ? 'Activar' : 'Actualizar'}
          </Button>,
        ]}
      />

      <PopUp
        state={{ open: openPausePopup, setOpen: setOpenPausePopup }}
        title={
          <Typography variant="h6" align="center">
            Advertencia
          </Typography>
        }
        content={
          <Typography sx={{ marginTop: '20px' }} variant="body1" align="center">
            Al pausar esta inscripción se eliminarán todas las cuotas que no se han pagado y no se
            cobrará más hasta que vuelvas a activar la inscripción. Esta acción es irreversible.
            ¿Quieres continuar?
          </Typography>
        }
        extraActions={[
          <Button onClick={() => setOpenPausePopup(false)} variant="outlined" key={2}>
            No
          </Button>,
          <Button onClick={markSubscriptionAsPaused} color="error" variant="contained" key={1}>
            Si
          </Button>,
        ]}
      />

      <PopUp
        state={{ open: openCancelPopup, setOpen: setOpenCancelPopup }}
        title={
          <Typography variant="h6" align="center">
            Advertencia
          </Typography>
        }
        content={
          <Typography sx={{ marginTop: '20px' }} variant="body1" align="center">
            Al cancelar esta inscripción se eliminarán todas las cuotas que no se han pagado y no se
            cobrará más. Esta acción es irreversible. ¿Quieres continuar?
          </Typography>
        }
        extraActions={[
          <Button onClick={() => setOpenCancelPopup(false)} variant="outlined" key={2}>
            No
          </Button>,
          <Button onClick={markSubscriptionAsCanceled} color="error" variant="contained" key={1}>
            Si
          </Button>,
        ]}
      />

      <PopUp
        state={{ open: openRetryChargePopup, setOpen: setOpenRetryChargePopup }}
        title={
          <Typography variant="h6" align="center">
            Advertencia
          </Typography>
        }
        content={
          <Typography sx={{ marginTop: '20px' }} variant="body1" align="center">
            Se intentará cobrar {formatCurrency(selectedStatement?.amount)} a la tajeta terminada en
            •••• {selectedStatement?.payable_card} de tu cliente {selectedStatement?.buyer?.name}.
            ¿Quieres continuar?
          </Typography>
        }
        extraActions={[
          <Button onClick={() => setOpenRetryChargePopup(false)} variant="outlined" key={2}>
            No
          </Button>,
          <Button onClick={retryCharge} color="error" variant="contained" key={1}>
            Si
          </Button>,
        ]}
      />

      <PopUp
        state={{ open: openRefundPopup, setOpen: setOpenRefundPopup }}
        title={
          <Typography variant="h6" align="center">
            Devolución pago
          </Typography>
        }
        content={
          <>
            <Typography sx={{ marginTop: '20px' }} variant="body1" align="center">
              Para devolver un pago tienes dos opciones, que la plataforma procese la devolución a
              través del medio de pago con el que se realizó el pago. O marcar el pago como
              &quot;Devuelto manualmente&quot; y que tú gestiones la devolución por fuera de la
              plataforma.
            </Typography>
          </>
        }
        extraActions={[
          <Button onClick={() => setOpenRefundPopup(false)} variant="outlined" key={1}>
            Cancelar
          </Button>,
          <Button
            color="info"
            variant="contained"
            onClick={() => refundStatement('refund')}
            key={2}
          >
            Devolver cuota
          </Button>,
          <Button
            onClick={() => {
              refundStatement('refund_manually');
            }}
            color="info"
            variant="contained"
            key={3}
          >
            Marcar como devuelta
          </Button>,
        ]}
      />

      <Modal open={selectBuyerOpen} onClose={() => setSelectBuyerOpen(false)}>
        <Container maxWidth="md" style={{ margin: 'auto' }}>
          <Paper className={cStyles.modal}>
            <div className={cStyles.modalHeader}>
              <Typography variant="h6">Selecciona clientes para agregar</Typography>
              <IconButton size="medium" color="primary" onClick={() => setSelectBuyerOpen(false)}>
                {loading ? <CircularProgress size={20} /> : <XIcon />}
              </IconButton>
            </div>
            <div style={{ textAlign: 'center', margin: 'auto' }}>
              <TextField
                hiddenLabel
                className={styles.queryBox}
                id="query"
                type="text"
                autoComplete="query"
                variant="outlined"
                size="small"
                value={query}
                onChange={handleQueryChange}
                InputProps={{
                  startAdornment: startAd(),
                  placeholder: 'Nombre, correo',
                }}
                fullWidth={isMobile}
              />
            </div>

            <TableList
              className={cStyles.modalTable}
              resources={buyers}
              headers={[
                { key: 'name', label: 'Nombre' },
                { key: 'email', label: 'Correo' },
              ]}
              columns={{
                name: (res: Buyer) => res.name,
                email: (res: Buyer) => res.email,
              }}
              listActions={{ add: (res: Buyer) => onBuyerSubscribe(res) }}
              listActionsHeaders={(res?: Buyer) => {
                if (res) return [{ key: 'add', label: 'Agregar' }];
                return [null];
              }}
            />
          </Paper>
        </Container>
      </Modal>
      <Menu
        keepMounted
        anchorEl={buyerMenuEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id="add-buyer-menu"
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(buyerMenuEl)}
        onClose={closeBuyerMenu}
      >
        <MenuItem onClick={onSubscribeBuyer}>Cliente existente</MenuItem>
        <MenuItem onClick={onCreateBuyer}>Crear cliente</MenuItem>
      </Menu>
      <Drawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <div className={styles.drawer}>
          <div className={styles.closePopup}>
            <div className={styles.xIcon} onClick={() => setDrawerOpen(false)}>
              <FontAwesomeIcon
                icon={faXmark}
                style={{ color: '#5f5f5f', width: '20px', height: '20px' }}
              />
            </div>
          </div>
          <Typography variant="h6">
            <b>Intentos de cobro</b>
          </Typography>
          <TableList
            resources={paymentTransactions}
            headers={[
              { key: 'payment_date', label: 'Fecha' },
              { key: 'status', label: 'Estado' },
              { key: 'payment_method', label: 'Método de Pago' },
              { key: 'error', label: 'Descripción' },
            ]}
            columns={{
              payment_date: (res: PaymentTransaction) => res.payment_date,
              status: (res: PaymentTransaction) => (
                <StatusLabel type="paymentTransaction" status={res.status} />
              ),
              payment_method: (res: PaymentTransaction) => res.payment_method,
              error: (res: PaymentTransaction) => res.error || 'Sin información del proveedor',
            }}
          />
        </div>
      </Drawer>
      <Drawer
        anchor="right"
        open={statementDrawerOpen}
        onClose={() => setStatementDrawerOpen(false)}
      >
        <div className={styles.drawer}>
          <div className={styles.closePopup}>
            <div className={styles.xIcon} onClick={() => setStatementDrawerOpen(false)}>
              <FontAwesomeIcon
                icon={faXmark}
                style={{ color: '#5f5f5f', width: '20px', height: '20px' }}
              />
            </div>
          </div>
          <Typography variant="h6">
            <b>Productos cobrados</b>
          </Typography>
          <TableList
            resources={Object.keys(statementProductHistory[0]?.subscription_products || {})}
            headers={[
              { key: 'name', label: 'Nombre' },
              { key: 'value', label: 'Valor' },
              { key: 'quantity', label: 'Cantidad' },
              { key: 'discounts', label: 'Descuentos' },
              { key: 'total', label: 'Total' },
            ]}
            columns={{
              name: (res: string) => statementProductHistory[0].subscription_products[res]['name'],
              value: (res: string) =>
                formatCurrency(statementProductHistory[0].subscription_products[res]['value']),
              quantity: (res: string) =>
                statementProductHistory[0].subscription_products[res]['quantity'],
              discounts: (res: string) =>
                formatCurrency(statementProductHistory[0].subscription_products[res]['discounts']),
              total: (res: string) =>
                formatCurrency(statementProductHistory[0].subscription_products[res]['total']),
            }}
          />
        </div>
      </Drawer>
      <Drawer
        anchor="right"
        open={openAddNoteDrawer}
        onClose={() => setOpenAddNoteDrawer(false)}
        className={styles.notesDrawerContainer}
      >
        <div className={styles.drawer}>
          <div className={styles.closePopup}>
            <div className={styles.xIcon} onClick={() => setOpenAddNoteDrawer(false)}>
              <FontAwesomeIcon
                icon={faXmark}
                style={{ color: '#5f5f5f', width: '20px', height: '20px' }}
              />
            </div>
          </div>
          <Typography variant="h6">
            Notas inscripción de {subBuyerState?.buyer.name} {subBuyerState?.buyer.last_name || ''}
          </Typography>
          <Box width="100%">
            <div className={styles.notesInput}>
              <TextField
                label="Agregar nota"
                variant="outlined"
                value={noteDescription}
                onChange={(e) => setNoteDescription(e.target.value)}
                className={styles.inputField}
              />
              <IconButton color="primary" sx={{ p: '10px' }} onClick={addNote}>
                <Typography color="primary">Agregar</Typography>
              </IconButton>
            </div>
          </Box>
          {notes.length > 0 && !loadingNotes && (
            <TableList
              resources={notes}
              headers={[
                { key: 'date', label: 'Fecha' },
                { key: 'description', label: 'Nota' },
              ]}
              columns={{
                date: (res: Note) => res.created_at,
                description: (res: Note) => res.description,
              }}
              listActions={{ delete: (note: Note) => deleteNote(note.id) }}
              listActionsHeaders={(res?: Note) => {
                if (res && !isMobile && res.user.id === user?.id)
                  return [{ key: 'delete', label: 'Borrar' }];
                return [null];
              }}
            />
          )}
        </div>
      </Drawer>
      <Drawer
        anchor="right"
        open={documentsDrawerOpen}
        onClose={() => setDocumentsDrawerOpen(false)}
        className={styles.notesDrawerContainer}
      >
        <div className={styles.drawer}>
          <div className={styles.closePopup}>
            <div className={styles.xIcon} onClick={() => setDocumentsDrawerOpen(false)}>
              <FontAwesomeIcon
                icon={faXmark}
                style={{ color: '#5f5f5f', width: '20px', height: '20px' }}
              />
            </div>
          </div>
          <Typography variant="h6">Documento tributario emitido</Typography>
          <TableList
            resources={selectedStatement?.invoice_documents || []}
            headers={[
              { key: 'date', label: 'Fecha' },
              { key: 'kind', label: 'Documento' },
              { key: 'external_status', label: 'Estado' },
              { key: 'actions', label: 'Acciones' },
            ]}
            columns={{
              date: (res: InvoiceDocument) => res.created_at,
              kind: (res: InvoiceDocument) => res.kind,
              external_status: (res: InvoiceDocument) => (
                <StatusLabel
                  type="invoiceDocument"
                  status={
                    ['no_folios', 'missing_data'].includes(res.status)
                      ? res.status
                      : res.external_status
                  }
                />
              ),
              actions: (res: InvoiceDocument) =>
                res.link ? (
                  <Typography
                    onClick={() => window.open(res.link, '_blank')}
                    sx={{ cursor: 'pointer' }}
                    fontSize={14}
                  >
                    <FontAwesomeIcon icon={faEye} style={{ marginRight: '10px' }} />
                    Ver documento
                  </Typography>
                ) : ['no_folios', 'missing_data'].includes(res.status) ? (
                  <Typography
                    onClick={() => generateDocument(res.id)}
                    sx={{ cursor: 'pointer' }}
                    fontSize={14}
                  >
                    <u>Reintentar</u>
                  </Typography>
                ) : (
                  '-'
                ),
            }}
          />
        </div>
      </Drawer>
      <Modal open={subscriptionsPopupOpen} onClose={() => setSubscriptionsPopupOpen(false)}>
        <Container maxWidth="md" style={{ margin: 'auto' }}>
          <Paper className={cStyles.modal}>
            <div className={cStyles.modalHeader}>
              <Typography variant="h6">
                Selecciona el servicio al que quieres cambiar al cliente
              </Typography>
              <IconButton
                size="medium"
                color="primary"
                onClick={() => setSubscriptionsPopupOpen(false)}
              >
                {loading ? <CircularProgress size={20} /> : <XIcon />}
              </IconButton>
            </div>
            <div style={{ textAlign: 'center', margin: 'auto' }}>
              <TextField
                hiddenLabel
                className={styles.queryBox}
                id="query"
                type="text"
                autoComplete="query"
                variant="outlined"
                size="small"
                value={query}
                onChange={handleSubscriptionQueryChange}
                InputProps={{
                  startAdornment: startAd(),
                  placeholder: 'Nombre suscripción',
                }}
                fullWidth={isMobile}
              />
            </div>
            <TableList
              className={cStyles.modalTable}
              resources={subscriptions.filter((sub) => sub.id !== subscription?.id)}
              headers={[
                { key: 'name', label: 'Nombre' },
                { key: 'value', label: 'Valor' },
              ]}
              columns={{
                name: (res: Subscription) => res.name,
                value: (res: Subscription) => formatCurrency(res.render_amount, res.currency),
              }}
              listActions={{ select: (res: Subscription) => onSubscriptionChange(res) }}
              listActionsHeaders={(res?: Subscription) => {
                if (res) return [{ key: 'select', label: 'Seleccionar' }];
                return [null];
              }}
            />
          </Paper>
        </Container>
      </Modal>
      <PopUp
        state={{ open: changeSubscriptionPopup, setOpen: setChangeSubscriptionPopup }}
        title={
          <Typography variant="h6" align="center">
            Cambio de suscripción
          </Typography>
        }
        content={
          <>
            <Typography sx={{ marginTop: '20px' }} variant="body1" align="center">
              Se inscribirá a {subBuyerState?.buyer.name} ({subBuyerState?.buyer.email}) a la
              suscripción <b>{selectedSubscription?.name}</b> con valor{' '}
              {formatCurrency(selectedSubscription?.render_amount, selectedSubscription?.currency)}.
              {showChangeSubscriptionForm ? (
                <Fragment>
                  <Typography variant="body1" mt={1} mb={2}>
                    Completa los siguientes campos necesarios para la inscripción.
                  </Typography>
                  {loadingSubscriptionUpdate ? (
                    <div className={styles.skeletonContainer}>
                      <Skeleton variant="rectangular" width="100%" height={50} />
                      <Skeleton variant="rectangular" width="100%" height={50} />
                    </div>
                  ) : (
                    selectedSubscription &&
                    subBuyerState && (
                      <ChangeSubscriptionForm
                        selectedSubscription={selectedSubscription}
                        subBuyerState={subBuyerState}
                        onSuccess={handleChangeSubscriptionSuccess}
                        setLoading={setLoading}
                        questions={questions}
                        formRef={formRef}
                      />
                    )
                  )}
                </Fragment>
              ) : (
                <Fragment>
                  <Typography variant="body1" mt={1} mb={1}>
                    Ten en consideración que:
                  </Typography>
                  <ul>
                    <li style={{ textAlign: 'left' }}>
                      {['paused', 'canceled'].includes(subBuyerState?.status || '')
                        ? 'La fecha de vencimiento de la primera cuota de la nueva inscripción será hoy '
                        : 'La fecha de cobro de la nueva inscripción será la misma que la de la inscripción actual '}
                      (puede ser modificada una vez creada).
                    </li>
                    {subBuyerState?.card ? (
                      <li style={{ textAlign: 'left' }}>
                        Se utilizará el medio de pago automático ya inscrito para la nueva
                        inscripción.
                      </li>
                    ) : (
                      ''
                    )}
                    <li style={{ textAlign: 'left' }}>
                      Se marcará como &quot;Terminada&quot; la inscripción actual y se eliminarán
                      todas las cuotas que no fueron pagadas
                      {['paused', 'canceled'].includes(subBuyerState?.status || '')
                        ? '.'
                        : ` (Total cuotas pendientes: ${formatCurrency(
                            subBuyerState?.total_to_pay,
                            subBuyerState?.current_statement?.currency
                          )})`}
                      .
                    </li>
                  </ul>
                </Fragment>
              )}
              <Typography variant="body1" mt={2}>
                Esta acción es irreversible. ¿Quieres continuar con el cambio?
              </Typography>
            </Typography>
          </>
        }
        extraActions={[
          <Button onClick={() => setChangeSubscriptionPopup(false)} variant="outlined" key={1}>
            Cancelar
          </Button>,
          <Button
            color="info"
            variant="contained"
            onClick={() => {
              if (showChangeSubscriptionForm && formRef.current) {
                formRef.current.requestSubmit();
              } else {
                handleChangeSubscription();
              }
            }}
            key={2}
            disabled={loading}
          >
            {loading ? <CircularProgress size={20} /> : 'Continuar'}
          </Button>,
        ]}
      />
    </>
  );
};

export default SubscriptionShow;
