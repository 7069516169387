export function allowedRole(access: string, route: string, action: string): boolean {
  if (!validateActionsAndResources(action, route)) {
    console.log('Error del sistema en la asignación de roles. Contactar con Zafepay');
  }
  if (access === 'undefined') {
    console.log('undefined access');
    return false;
  }

  switch (access) {
    case 'admin':
      return true;
    case 'viewer':
      return viewerRoles(route, action);
    case 'editor':
      return editorRoles(route, action);
    case 'accountant':
      return accountantRoles(route);
    case 'accountant_viewer':
      return accountantViewerRoles(route, action);
    case 'accountant_editor':
      return accountantEditorRoles(route, action);
    default:
      return false;
  }
}
const transformActions: string[] = ['create', 'update', 'destroy', 'action'];
const readActions: string[] = ['list', 'read'];
const allActions: string[] = [...transformActions, ...readActions];

function viewerRoles(route: string, action: string): boolean {
  if (!readActions.includes(action)) return false;
  if (route === 'payment' || route === 'configuration') return false;
  return true;
}

function editorRoles(route: string, action: string): boolean {
  if (route === 'payment' || route === 'configuration' || !allActions.includes(action))
    return false;
  return true;
}

function accountantRoles(route: string): boolean {
  if (route !== 'payment') {
    return false;
  }

  return true;
}

function accountantViewerRoles(route: string, action: string): boolean {
  if (!readActions.includes(action) || route === 'configuration') return false;
  return true;
}

function accountantEditorRoles(route: string, action: string): boolean {
  if (route === 'configuration' || !allActions.includes(action)) return false;
  return true;
}

function validateActionsAndResources(actions: string, resources: string): boolean {
  if (!listOfActions.includes(actions)) {
    console.log('actions', actions);
    console.log('resources', resources);
    return false;
  }
  if (!listOfResources.includes(resources)) {
    console.log('actions', actions);
    console.log('resources', resources);
    return false;
  }
  return true;
}

const listOfActions: string[] = [
  'create', // Crear nuevos registros
  'update', // Actualizar registros
  'destroy', // Eliminar registros
  'list', // Listar todos los registros
  'read', // Leer un registro
  'action', // Acciones especiales
];

const listOfResources: string[] = [
  'product',
  'single_payment',
  'subscription',
  'payment_plan',
  'buyer',
  'payment',
  'configuration',
];

export const sellerActions = {
  create: 'create',
  update: 'update',
  destroy: 'destroy',
  action: 'action',
  list: 'list',
  read: 'read',
};
