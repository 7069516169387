import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';

import { CheckoutProduct, Discount, Payable, SubscriptionProduct } from '../../../../app/type';

import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
  Skeleton,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faXmark } from '@fortawesome/free-solid-svg-icons';

import styles from './styles.module.scss';
import { formatCLP, formatCurrency } from '../../../../common/utils';
import { hexToRgb } from '../../../client/utils';

interface ComponentProps {
  payable?: Payable;
  setPayable: (arg: Payable) => void;
  customRecurrenceProps?: {
    selectedRecurrence: string | null;
    setSelectedRecurrence: (arg: string | null) => void;
    amount: number | null;
    setAmount: (arg: number | null) => void;
    errors: { [key: string]: string | null };
  };
  step: string;
  shippingCost: number | null;
  setChangedQty?: () => void;
  loadingStock?: boolean;
  loadingShippingCost?: boolean;
  extraDiscount?: Partial<Discount>;
  productsToSubscribeState?: {
    state: string[] | undefined;
    setState: (a: string[]) => void;
  };
  codeDiscountState?: {
    state: string | undefined;
    setState: (a: string) => void;
  };
}

const ProductDisplayerAndSelector = (props: ComponentProps): React.ReactElement => {
  const isTablet = useMediaQuery(`(max-width: 1330px)`);
  const [removePopUp, setRemovePopUp] = useState<[boolean, string]>();
  const [subtotal, setSubtotal] = useState<number>();
  const [subtotalWithDiscount, setSubtotalWithDiscount] = useState<number>();
  const [subscribableProducts, setSubscribableProducts] = useState<boolean>();
  const [subscribeProducts, setSubscribeProducts] = useState<boolean>();
  const [allSubscribed, setAllSubscribed] = useState<boolean>();
  const [showDetail, setShowDetail] = useState<boolean>(false);
  const [overDiscountedCode, setOverDiscountedCode] = useState<boolean>(false);
  const [extraDiscountPercentageValue, setExtraDiscountPercentageValue] = useState<number>(0);

  const rgbColor = hexToRgb(props.payable?.company.payment_design?.background_color || '#4653E3');
  const cardsBackgroundColor = rgbColor
    ? `rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, 0.1)`
    : '#f5f6ff';
  const productsCardsBackgroundColor = rgbColor
    ? `rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, 0.1)`
    : '#f5f6ff';
  const { enqueueSnackbar } = useSnackbar();
  const [changedPayableProducts, setChangedPayableProducts] = useState<boolean>(false);

  let discountedValue = 0;
  const overDiscountedIds: string[] = [];

  const handleQuantityChange = (
    change: 'increment' | 'decrement',
    id: string,
    customQty?: number
  ) => {
    const newPayableProducts = props.payable?.payable_products?.map((prod) => {
      if (prod.id !== id) {
        return prod;
      }
      const qty =
        typeof customQty === 'number'
          ? customQty
          : prod.quantity + (change === 'increment' ? 1 : -1);
      if (
        change === 'increment' &&
        typeof prod.product.stock === 'number' &&
        prod.product.stock < qty
      ) {
        enqueueSnackbar(
          <Typography fontFamily="Poppins">Estás comprando todo el stock disponible</Typography>,
          {
            variant: 'info',
            autoHideDuration: 2000,
          }
        );
        return prod;
      }
      if (prod.quantity <= 0 && change === 'decrement') {
        return prod;
      }
      setChangedPayableProducts(!changedPayableProducts);
      return {
        ...prod,
        quantity: qty,
      };
    });
    if (props.payable?.company) {
      props.setPayable({ ...props.payable, payable_products: newPayableProducts });
    }
  };

  useEffect(() => {
    if (props.payable) {
      setSubtotal(
        props.payable?.payable_products
          ?.map((prod) =>
            Math.ceil(
              prod.quantity *
                ((props.productsToSubscribeState?.state?.includes(prod.id) &&
                  !props.payable?.modificable_recurrence) ||
                props.payable?.modificable_recurrence
                  ? prod.product.render_value || prod.product.value
                  : prod.product.value)
            )
          )
          .reduce((prev, curr) => prev + curr, 0)
      );
    }
  }, [props.payable, props.productsToSubscribeState?.state]);

  function getSubtotalBeforeAnyDiscount(): number {
    if (props.payable) {
      const subtotal: number =
        props.payable?.payable_products
          ?.map((prod) => (prod.quantity || 0) * (prod.product.value || 0))
          .reduce((prev, curr) => prev + curr, 0) || 0;
      return subtotal;
    }
    return 0;
  }

  useEffect(() => {
    if (props.extraDiscount) {
      setExtraDiscountPercentageValue(
        props.extraDiscount.kind === 'amount' ? 0 : props.extraDiscount.value || 0
      );
    } else {
      setExtraDiscountPercentageValue(0);
    }
  }, [props.extraDiscount]);

  useEffect(() => {
    if (subtotal) {
      if (props.extraDiscount?.value) {
        setSubtotalWithDiscount(
          Math.ceil(
            props.payable?.payable_products
              ?.map(
                (prod) =>
                  prod.quantity *
                  prod.product.value *
                  (1 -
                    (((props.productsToSubscribeState?.state?.includes(prod.id) &&
                      !props.payable?.modificable_recurrence) ||
                    props.payable?.modificable_recurrence
                      ? prod.product.percentage_discount || 0
                      : 0) +
                      (extraDiscountPercentageValue || 0)) /
                      100)
              )
              .reduce((prev, curr) => prev + curr, 0) || 0
          ) - (props.extraDiscount.kind === 'amount' ? props.extraDiscount.value : 0) || 0 // se resta el valor del cupón en caso que sea un monto
        );
      } else {
        setSubtotalWithDiscount(subtotal);
      }
    }

    const initial_total: number = getSubtotalBeforeAnyDiscount();
    if (props.payable?.company?.max_discount_allowed && initial_total && subtotal) {
      if (initial_total - subtotal > props.payable?.company?.max_discount_allowed) {
        setSubtotal(initial_total - props.payable?.company?.max_discount_allowed);
      }
    }
  }, [subtotal, props.extraDiscount]);

  useEffect(() => {
    const initial_total: number = getSubtotalBeforeAnyDiscount();
    if (props.payable?.company?.max_discount_allowed && initial_total && subtotalWithDiscount) {
      if (
        initial_total - subtotalWithDiscount >=
        (props.payable?.company?.max_discount_allowed || 100_000_000_000)
      ) {
        setOverDiscountedCode(true);
        setSubtotalWithDiscount(initial_total - props.payable?.company?.max_discount_allowed);
        return;
      }
    }
    setOverDiscountedCode(false);
  }, [subtotalWithDiscount]);

  useEffect(() => {
    props.setChangedQty && props.setChangedQty();
  }, [changedPayableProducts]);

  const decreaseProduct = (productId: string) => {
    let currentQuantity: number | undefined = undefined;
    props.payable?.payable_products?.forEach((prod) => {
      if (prod.id === productId) {
        currentQuantity = prod.quantity;
      }
    });
    if (currentQuantity) {
      if (currentQuantity === 1) {
        setRemovePopUp([true, productId]);
      } else {
        handleQuantityChange('decrement', productId);
      }
    }
  };

  const removeProduct = () => {
    if (removePopUp) {
      const products = props.payable?.payable_products?.filter((pp) => pp.quantity > 0);
      if (products && products.length > 1) {
        handleQuantityChange('decrement', removePopUp[1], 0);
        handleSubscribeProduct(removePopUp[1]);
        setRemovePopUp(undefined);
      } else {
        setRemovePopUp(undefined);
        enqueueSnackbar(
          <Typography fontFamily="Poppins">El carrito no puede quedar vacío</Typography>,
          {
            variant: 'info',
            autoHideDuration: 2000,
          }
        );
      }
    }
  };

  const handleSubscribeProduct = (pp_id: string) => {
    if (props.productsToSubscribeState?.state?.includes(pp_id)) {
      props.productsToSubscribeState?.setState(
        props.productsToSubscribeState?.state.filter((id: string) => id !== pp_id)
      );
    } else {
      props.productsToSubscribeState?.setState([
        ...(props.productsToSubscribeState?.state || []),
        pp_id,
      ]);
    }
  };

  const applyThisDiscount = (
    sp: SubscriptionProduct | CheckoutProduct,
    normal_discounted_price: number
  ) => {
    const isSuscribed = props.productsToSubscribeState?.state?.includes(sp.id);
    if (props.payable?.company.max_discount_allowed && sp.product && isSuscribed) {
      const discountPerProduct = ((sp.product.percentage_discount || 0) / 100) * sp.product.value;

      if (
        Math.ceil(sp.quantity * discountPerProduct) + discountedValue >
        props.payable.company.max_discount_allowed
      ) {
        const toReturn =
          sp.product.value * sp.quantity -
          (props.payable.company.max_discount_allowed - discountedValue);
        discountedValue = props.payable.company.max_discount_allowed;

        overDiscountedIds.push(sp.id);

        return toReturn;
      } else {
        discountedValue = discountedValue + Math.ceil(sp.quantity * discountPerProduct);
        return normal_discounted_price;
      }
    } else {
      return normal_discounted_price;
    }
  };

  const ammountToRender = (sp: SubscriptionProduct | CheckoutProduct) => {
    const normal_discounted_price =
      sp.quantity *
      (props.payable?.modificable_recurrence
        ? sp.product.render_value || sp.product.value
        : (props.productsToSubscribeState?.state || []).includes(sp.id)
        ? sp.product.render_value || sp.product.value
        : sp.product.value);
    const toRender = applyThisDiscount(sp, normal_discounted_price);
    return toRender;
  };

  const summaryProduct = (
    index: number,
    value: SubscriptionProduct | CheckoutProduct,
    subscribe: boolean
  ) => {
    return (
      <div
        key={index}
        className={styles.paymentProductContainer}
        style={{ background: productsCardsBackgroundColor }}
      >
        <img src={value.product.external_image} />
        <div className={styles.productName}>
          <Typography variant="h5">{value.product.name}</Typography>
          <div className={styles.productQuantityContainer}>
            <Typography variant="subtitle1">
              {value.quantity} producto{value.quantity > 1 ? 's' : ''}{' '}
            </Typography>
          </div>
        </div>
        <Typography>
          {formatCurrency(
            Math.ceil(
              value.quantity *
                (props.productsToSubscribeState?.state
                  ? props.productsToSubscribeState?.state.includes(value.id)
                    ? value.product.render_value || value.product.value
                    : value.product.value
                  : value.product.render_value || value.product.value)
            ),
            value.product.currency
          )}
          {subscribe && `/${props.customRecurrenceProps?.selectedRecurrence}`}
        </Typography>
      </div>
    );
  };

  useEffect(() => {
    if (props.payable) {
      setSubscribableProducts(
        props.payable.payable_products?.some((pp) => pp.subscribable === true)
      );
    }
  }, [props.payable]);

  function compareStrings(a: string, b: string): number {
    if (a < b) {
      return -1;
    } else if (a > b) {
      return 1;
    }
    return 0;
  }

  useEffect(() => {
    if (props.productsToSubscribeState?.state && props.productsToSubscribeState?.state.length > 0) {
      setSubscribeProducts(true);
      const products = props.payable?.payable_products?.filter((pp) => pp.quantity > 0);
      if (products?.length === props.productsToSubscribeState?.state.length) {
        setAllSubscribed(true);
      } else {
        setAllSubscribed(false);
      }
    } else {
      setSubscribeProducts(false);
      setAllSubscribed(false);
    }
  }, [props.productsToSubscribeState?.state]);

  return (
    <>
      {props.step === 'payment' ? (
        isTablet ? (
          <div className={styles.paymentSummaryContainer}>
            <Typography
              fontWeight={600}
              fontSize={20}
              textAlign="center"
              color={`${
                props.payable?.company.payment_design?.background_color || '#4653E3'
              } !important`}
            >
              Resumen de tu compra
            </Typography>
            <div className={styles.amountSummary} style={{ backgroundColor: cardsBackgroundColor }}>
              <div className={styles.quantity}>
                <Typography variant="subtitle2">Cantidad de productos</Typography>
                <Typography variant="h4">
                  {props.payable?.payable_products
                    ?.map((prod) => prod.quantity)
                    .reduce((prev, curr) => prev + curr, 0)}
                </Typography>
              </div>
              <div className={styles.total}>
                <Typography variant="subtitle2">Total</Typography>
                <Typography variant="h4">
                  {formatCLP((subtotalWithDiscount || 0) + (props.shippingCost || 0))}
                </Typography>
              </div>
            </div>
            {subscribeProducts && (
              <Accordion
                expanded={showDetail}
                onChange={() => setShowDetail(!showDetail)}
                sx={{ marginBottom: '10px', marginTop: '10px' }}
                className={styles.accordion}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography>Detalle de la compra</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="subtitle1" fontSize={14}>
                    Estos son los productos que se te cobrarán
                  </Typography>
                  {((subscribeProducts && !allSubscribed) || !subscribeProducts) && (
                    <Typography variant="subtitle1" fontSize={14}>
                      <b>Sólo en esta compra:</b>
                    </Typography>
                  )}
                  {props.payable?.payable_products?.map((value, index) =>
                    !props.productsToSubscribeState?.state?.includes(value.id) && value.quantity > 0
                      ? summaryProduct(index, value, false)
                      : null
                  )}
                  {subscribeProducts && (
                    <Typography variant="subtitle1" mt={1} fontSize={14}>
                      <b>En esta compra y en la compra recurrente que se creará:</b>
                    </Typography>
                  )}
                  {subscribeProducts &&
                    props.payable?.payable_products?.map((value, index) =>
                      props.productsToSubscribeState?.state?.includes(value.id) &&
                      value.quantity > 0
                        ? summaryProduct(index, value, true)
                        : null
                    )}
                </AccordionDetails>
              </Accordion>
            )}
          </div>
        ) : (
          <div className={styles.container} style={{ background: cardsBackgroundColor }}>
            <Typography
              variant="h4"
              color={`${props.payable?.company.payment_design?.background_color} !important`}
              fontWeight={600}
              fontSize={25}
            >
              Productos seleccionados
            </Typography>
            <Typography variant="subtitle1">Estos son los productos que se te cobrarán</Typography>
            {((subscribeProducts && !allSubscribed) || !subscribeProducts) && (
              <Typography variant="subtitle1">
                {props.payable?.modificable_recurrence
                  ? 'En esta compra y en la compra recurrente que se creará:'
                  : 'Sólo en esta compra:'}
              </Typography>
            )}
            {props.payable?.payable_products?.map((value, index) =>
              !props.productsToSubscribeState?.state?.includes(value.id) && value.quantity > 0
                ? summaryProduct(index, value, false)
                : null
            )}
            {subscribeProducts && (
              <Typography variant="subtitle1">
                En esta compra y en la compra recurrente que se creará:
              </Typography>
            )}
            {subscribeProducts &&
              props.payable?.payable_products?.map((value, index) =>
                props.productsToSubscribeState?.state?.includes(value.id) && value.quantity > 0
                  ? summaryProduct(index, value, true)
                  : null
              )}
            {props.extraDiscount?.value && (
              <div className={styles.paymentDeliveryContainer}>
                <Typography>Descuento código</Typography>
                <Typography>
                  {props.extraDiscount.kind === 'percentage'
                    ? `${props.extraDiscount.value}%`
                    : `${formatCurrency(props.extraDiscount.value)}`}
                </Typography>
              </div>
            )}
            <div className={styles.paymentDeliveryContainer}>
              <Typography>Costo de envío</Typography>
              <Typography>
                {props.loadingShippingCost
                  ? 'Cargando...'
                  : typeof props.shippingCost === 'number'
                  ? formatCLP(props.shippingCost)
                  : 'Calculado al seleccionar comuna'}
              </Typography>
            </div>
            <div className={styles.paymentTotal}>
              <Typography>
                <b>Total</b>
              </Typography>
              <div className={styles.priceWrapper}>
                <Typography>
                  <b>{formatCLP((subtotalWithDiscount || 0) + (props.shippingCost || 0))}</b>
                </Typography>
                {props.productsToSubscribeState?.state === undefined && (
                  <Typography variant="subtitle1">
                    /{props.customRecurrenceProps?.selectedRecurrence || 'Seleccionar recurrencia'}
                  </Typography>
                )}
              </div>
            </div>
          </div>
        )
      ) : (
        <div className={styles.container} style={{ background: cardsBackgroundColor }}>
          <Typography
            variant="h4"
            color={`${props.payable?.company.payment_design?.background_color} !important`}
            fontWeight={600}
            fontSize={25}
          >
            {subscribableProducts ? 'Carrito de compra' : 'Productos seleccionados'}
          </Typography>

          {subscribableProducts ? (
            <Typography variant="subtitle1">
              Si quieres crear una compra recurrente,{' '}
              <b>selecciona a continuación los productos a los que te quieras suscribir.</b> Los que
              no selecciones serán cobrados solo en esta compra:
            </Typography>
          ) : (
            <Typography variant="subtitle1">Estos son los productos que se te cobrarán:</Typography>
          )}
          {props.payable?.payable_products
            ?.sort((a, b) => compareStrings(a.product.id, b.product.id))
            .map((value, index) =>
              !props.loadingStock ? (
                value.quantity > 0 ? (
                  <div
                    key={index}
                    className={styles.productContainer}
                    style={{
                      background: productsCardsBackgroundColor,
                      cursor: value.subscribable ? 'pointer' : 'unset',
                    }}
                    onClick={() =>
                      value.subscribable ? handleSubscribeProduct(value.id) : undefined
                    }
                  >
                    <div className={styles.mainContainer}>
                      <div className={styles.imageNameContainer}>
                        <img src={value.product.external_image} />
                        <div className={styles.titleContainer}>
                          <Typography variant="h5">{value.product.name}</Typography>
                          <Typography variant="subtitle1">{value.product.description}</Typography>
                        </div>
                      </div>
                      <div className={styles.selectorPriceContainer}>
                        <div className={styles.price}>
                          {value.product.percentage_discount ? (
                            (props.productsToSubscribeState?.state?.includes(value.id) &&
                              !props.payable?.modificable_recurrence) ||
                            props.payable?.modificable_recurrence ? (
                              <div className={styles.discount}>
                                <Typography className={styles.discount}>
                                  <span className={styles.strikethrough}>
                                    {formatCurrency(value.product.value, value.product.currency)}
                                  </span>{' '}
                                  (-{value.product?.percentage_discount}%)
                                </Typography>
                              </div>
                            ) : null
                          ) : null}
                          <Typography variant="h5">
                            {formatCurrency(
                              Math.ceil(ammountToRender(value)),
                              value.product.currency
                            )}
                            {overDiscountedIds.includes(value.id) && '*'}
                          </Typography>
                        </div>

                        <div className={styles.selectorContainer}>
                          {!props.payable?.payment_link_configuration?.hide_edit_quantity && (
                            <div className={styles.selector}>
                              <div
                                className={styles.leftButton}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  decreaseProduct(value.id);
                                }}
                              >
                                <FontAwesomeIcon icon={faChevronDown} />
                              </div>
                              <div className={styles.quantity}>
                                <Typography variant="subtitle1">{value.quantity}</Typography>
                              </div>
                              <div
                                className={styles.rightButton}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleQuantityChange('increment', value.id);
                                }}
                              >
                                <FontAwesomeIcon icon={faChevronUp} />
                              </div>
                            </div>
                          )}
                          {value.subscribable && (
                            <div>
                              <Checkbox
                                checked={props.productsToSubscribeState?.state?.includes(value.id)}
                                style={{
                                  color:
                                    props.payable?.company.payment_design?.background_color ||
                                    '#4653E3',
                                }}
                                onClick={() => handleSubscribeProduct(value.id)}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      className={styles.xMark}
                      onClick={(e) => {
                        e.stopPropagation();
                        setRemovePopUp([true, value.id]);
                      }}
                    >
                      <FontAwesomeIcon icon={faXmark} />
                    </div>
                  </div>
                ) : null
              ) : (
                <Skeleton key={index} variant="rectangular" width="100%" />
              )
            )}
          {overDiscountedIds.length > 0 && (
            <div className={styles.deliveryContainer}>
              <Typography>* Algunos descuentos exceden el máximo permitido.</Typography>
            </div>
          )}
          {!isTablet && !props.loadingStock ? (
            <Footer
              payable={props.payable}
              customRecurrenceProps={props.customRecurrenceProps}
              step={props.step}
              shippingCost={props.shippingCost}
              loadingShippingCost={props.loadingShippingCost}
              extraDiscount={props.extraDiscount}
              subtotal={subtotal || 0}
              subtotalWithDiscount={subtotalWithDiscount || 0}
              productsToSubscribe={props.productsToSubscribeState?.state}
              discountedValue={discountedValue || 0}
              overDiscountedCode={overDiscountedCode}
            />
          ) : null}
        </div>
      )}
      <Dialog open={Boolean(removePopUp?.[0])} onClose={() => setRemovePopUp(undefined)}>
        <DialogTitle>
          <Typography fontFamily="Poppins">¿Quieres eliminar este producto de tu carro?</Typography>
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => setRemovePopUp(undefined)}
            variant="outlined"
            style={{
              borderColor: props.payable?.company.payment_design
                ? props.payable?.company.payment_design.background_color
                : '#4653E3',
              color: props.payable?.company.payment_design
                ? props.payable?.company.payment_design.background_color
                : '#4653E3',
            }}
          >
            No
          </Button>
          <Button
            onClick={removeProduct}
            variant="contained"
            style={{
              backgroundColor: props.payable?.company.payment_design
                ? props.payable?.company.payment_design.background_color
                : '#4653E3',
            }}
          >
            Sí
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

interface FooterProps {
  payable?: Payable;
  customRecurrenceProps?: {
    selectedRecurrence: string | null;
    setSelectedRecurrence: (arg: string | null) => void;
    amount: number | null;
    setAmount: (arg: number | null) => void;
    errors: { [key: string]: string | null };
  };
  step: string;
  shippingCost: number | null;
  loadingShippingCost?: boolean;
  extraDiscount?: Partial<Discount>;
  subtotal: number;
  subtotalWithDiscount: number;
  productsToSubscribe?: string[];
  discountedValue?: number;
  overDiscountedCode?: boolean;
}

const Footer = (props: FooterProps) => {
  const [companyColor, setCompanyColor] = useState<string>('#4653E3');
  const [subscriptionSubotal, setSubscriptionSubotal] = useState<number>();

  const applyDynamicBorder = (element: HTMLElement) => {
    element.style.setProperty('--dynamic-border-color', companyColor);
  };

  const removeDynamicBorder = (element: HTMLElement) => {
    element.style.setProperty('--dynamic-border-color', companyColor);
  };

  useEffect(() => {
    if (props.payable) {
      setSubscriptionSubotal(
        props.payable?.payable_products
          ?.map((prod) => {
            const normal_price = getNormalDiscountedPrice(prod);

            const appliedDiscount = applyThisDiscount(prod, normal_price);

            return appliedDiscount;
          })
          .reduce((prev, curr) => prev + curr, 0)
      );
    }
  }, [props.payable, props.productsToSubscribe]);

  useEffect(() => {
    if (props.payable) {
      setCompanyColor(
        props.payable?.company.payment_design
          ? props.payable?.company.payment_design.background_color
          : '#4653E3'
      );
    }
  });

  const getNormalDiscountedPrice = (sp: SubscriptionProduct | CheckoutProduct) => {
    return Math.ceil(
      sp.quantity *
        (props.payable?.modificable_recurrence
          ? sp.product.render_value || sp.product.value
          : props.productsToSubscribe?.includes(sp.id) || props.payable?.modificable_recurrence
          ? sp.product.render_value || sp.product.value
          : sp.product.value)
    );
  };

  let footerDiscountedValue = 0;

  const applyThisDiscount = (
    sp: SubscriptionProduct | CheckoutProduct,
    normal_discounted_price: number
  ) => {
    const isSuscrited = props.productsToSubscribe?.includes(sp.id);

    if (!isSuscrited) {
      return 0;
    }

    if (props.payable?.company.max_discount_allowed && sp.product) {
      const discountPerProduct = ((sp.product.percentage_discount || 0) / 100) * sp.product.value;
      if (
        Math.ceil(sp.quantity * discountPerProduct) + footerDiscountedValue >
        props.payable.company.max_discount_allowed
      ) {
        const toReturn =
          sp.product.value * sp.quantity -
          (props.payable.company.max_discount_allowed - footerDiscountedValue);
        footerDiscountedValue = props.payable.company.max_discount_allowed;

        return toReturn;
      } else {
        footerDiscountedValue = footerDiscountedValue + Math.ceil(sp.quantity * discountPerProduct);
        return normal_discounted_price;
      }
    } else {
      return normal_discounted_price;
    }
  };

  return (
    <div className={styles.footerContainer}>
      <div className={styles.subtotalContainer}>
        {props.productsToSubscribe && props.productsToSubscribe.length > 0 && (
          <div className={styles.subtotalRow}>
            <Typography>Valor compra recurrente</Typography>
            <div className={styles.priceWrapper}>
              <Typography variant="h5">{formatCLP(subscriptionSubotal)}</Typography>
              <Typography variant="h6">
                /{props.customRecurrenceProps?.selectedRecurrence || 'Seleccionar recurrencia'}
              </Typography>
            </div>
          </div>
        )}
        <div className={styles.subtotalRow}>
          <Typography>Subtotal</Typography>
          <div className={styles.price}>
            {props.extraDiscount ? (
              <div className={styles.discount}>
                <Typography className={styles.discount}>
                  <span className={styles.strikethrough}>{formatCLP(props.subtotal)}</span> -
                  {props.extraDiscount.kind === 'percentage'
                    ? `${props.extraDiscount.value}%`
                    : `${formatCurrency(props.extraDiscount.value)}`}
                  {props.overDiscountedCode ? '**' : null}
                </Typography>
              </div>
            ) : null}
            <Typography variant="h5">{formatCLP(props.subtotalWithDiscount)}</Typography>
          </div>
        </div>
      </div>
      <div className={styles.deliveryContainer}>
        <Typography>Costo de envío</Typography>
        <Typography>
          {props.loadingShippingCost
            ? 'Cargando...'
            : typeof props.shippingCost === 'number'
            ? formatCLP(props.shippingCost)
            : 'Calculado al seleccionar comuna'}
        </Typography>
      </div>
      <div className={styles.totalContainer}>
        <Typography>
          <b>Total</b>
        </Typography>
        <div className={styles.priceWrapper}>
          <Typography>
            <b>{formatCLP((props.subtotalWithDiscount || 0) + (props.shippingCost || 0))}</b>
          </Typography>
          {props.productsToSubscribe === undefined && (
            <Typography variant="subtitle1">
              /{props.customRecurrenceProps?.selectedRecurrence || 'Seleccionar recurrencia'}
            </Typography>
          )}
        </div>
      </div>
      {(props.overDiscountedCode || false) && props.extraDiscount && (
        <div className={styles.subtotalRow}>
          <Typography className={styles.extraAnnotation}>
            ** Has alcanzado el máximo de descuento permitido por la empresa
          </Typography>
        </div>
      )}
      {props.step !== 'payment' ? (
        props.productsToSubscribe === undefined || props.productsToSubscribe.length > 0 ? (
          <div className={styles.recurrenceContainer}>
            <Typography>
              Recuerda que{' '}
              {props.productsToSubscribe === undefined
                ? 'estos productos'
                : 'los productos que selecciones arriba'}{' '}
              se te cobrarán y enviarán según la <b>recurrencia</b> que escojas.{' '}
            </Typography>
            <div className={styles.selectors}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography>
                    <b>Recurrencia del pago</b>
                  </Typography>
                  <TextField
                    select
                    fullWidth
                    value={props.customRecurrenceProps?.selectedRecurrence || ''}
                    onChange={(event) => {
                      props.customRecurrenceProps?.setSelectedRecurrence(event.target.value);
                      event.target.value !== 'custom' &&
                        props.customRecurrenceProps?.setAmount(null);
                    }}
                    defaultValue={props.payable?.recurrence}
                    error={Boolean(props.customRecurrenceProps?.errors.recurrence)}
                    helperText={props.customRecurrenceProps?.errors.recurrence}
                    onMouseEnter={(e) => applyDynamicBorder(e.currentTarget)}
                    onMouseLeave={(e) => removeDynamicBorder(e.currentTarget)}
                    onFocus={(e) => applyDynamicBorder(e.currentTarget)}
                    onBlur={(e) => removeDynamicBorder(e.currentTarget)}
                  >
                    {props.payable?.payment_link_configuration?.translated_payment_frequency.map(
                      (frequency) =>
                        frequency === 'Puntual' ? null : (
                          <MenuItem key={frequency} value={frequency}>
                            {frequency}
                          </MenuItem>
                        )
                    )}
                  </TextField>
                </Grid>
                {props.customRecurrenceProps?.selectedRecurrence === 'Personalizada' ? (
                  <Grid item xs={6}>
                    <Typography>
                      <b>Cantidad de días</b>
                    </Typography>
                    <TextField
                      fullWidth
                      type="number"
                      onChange={(
                        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                      ) => {
                        const string = event.target.value
                          .toString()
                          .replace('-', '')
                          .replace('.', '');
                        props.customRecurrenceProps?.setAmount(parseInt(string));
                      }}
                      value={props.customRecurrenceProps?.amount}
                      error={Boolean(props.customRecurrenceProps?.errors.amount)}
                      helperText={props.customRecurrenceProps?.errors.amount}
                      onMouseEnter={(e) => applyDynamicBorder(e.currentTarget)}
                      onMouseLeave={(e) => removeDynamicBorder(e.currentTarget)}
                      onFocus={(e) => applyDynamicBorder(e.currentTarget)}
                      onBlur={(e) => removeDynamicBorder(e.currentTarget)}
                      InputProps={{ inputProps: { min: 7, max: 90 } }}
                    />
                  </Grid>
                ) : null}
              </Grid>
            </div>
          </div>
        ) : null
      ) : null}
    </div>
  );
};

export default ProductDisplayerAndSelector;
